import React from "react";
import { Link } from "react-router-dom";
import { privacy_policy_path, terms_condition_path } from "../../config/config";

export default function Footer() {
  return (
    <footer className="page-footer footer">
      <div className="row">
        {/* <div className="col s12 l1"></div>
        <div className="col s6 l2 left" style={{ paddingTop: "2%" }}>
          <img
            id="footer-logo"
            className="brand-logo"
            src="./images/logo.png"
            alt="./images/peopleai-logo-transparent.png"
            height="100px"
          />
        </div> */}
        <div className="col s12 l4 left footer-info">
          <h5>Write to Us</h5>
          <strong className="onHover">
            email:{" "}
            <a href="mailto:info@peopleai.com.au">info@peopleai.com.au</a>
          </strong>
        </div>
        <div className="col s12 l4 left footer-info" id="policy">
          <h5>Our Policies</h5>
          {/* <strong>Cookie Policy</strong> */}
          <ul>
            <li>
              <Link className="footer-link" to={privacy_policy_path}>
                Privacy Policy
              </Link>
            </li>
            <li>
              <Link className="footer-link" to={terms_condition_path}>
                Terms and Conditions
              </Link>
            </li>
          </ul>
        </div>
        <div className="col s12 l4 left" id="copywright">
          <h4>People AI</h4>
          <strong>© 2024 People AI. All Rights Reserved.</strong>
        </div>
      </div>
    </footer>
  );
}
