import React from "react";

export default function SqlOutput({ label, sql, output, optional }) {
  return (
    <div>
      <h5>{label}</h5>
      <p className="code">{sql + ";"}</p>
      <p className="code">{output && JSON.stringify(output)}</p>
      {optional && (
        <p className="code">
          {" "}
          <p style={{ color: "red" }}>Code used</p>
          {optional}
        </p>
      )}
    </div>
  );
}
