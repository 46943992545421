import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import LoaderComp from "../../Layouts/LoaderComp";
import { customYAxisFormatter } from "../../../utils/salaryDashboardDataHeler";
import { useDispatch, useSelector } from "react-redux";
import { setSalarydashboard } from "../../../store/action/salaryDashboardAction";
import { pastelColors } from "../../../config/constants";

export default function MedianSalaryColumnChart({ role }) {
  const dispatch = useDispatch();
  const isDemoUser = role === "demo";

  const chart2 = useSelector((state) => state.salaryDashboard?.chart2);
  const chart2loading = useSelector(
    (state) => state.salaryDashboard?.chart2loading
  );
  const chart2success = useSelector(
    (state) => state.salaryDashboard?.chart2success
  );
  const chart2error = useSelector(
    (state) => state.salaryDashboard?.chart2error
  );
  const common = useSelector((state) => state.common);

  const [location, setLocation] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (chart2) {
      let d = [];
      let l = [];
      let totalOther = 0;
      let otherCount = 0;
      for (let i = 0; i < chart2?.length; i++) {
        const colorIndex = i % pastelColors.length; // Cycle through the pastel colors

        if (chart2[i]?.location?.toLowerCase()?.includes("others")) {
          totalOther = totalOther + Number(chart2[i]?.median);
          otherCount++;
        } else {
          if (Number(chart2[i]?.median) !== 0) {
            l.push(chart2[i]?.location);
            d.push({
              x: chart2[i]?.location,
              y: Number(chart2[i]?.median),
              fillColor: pastelColors[colorIndex], // Assign pastel color
              fill: {
                type: "solid",
                gradient: {
                  shade: pastelColors[colorIndex],
                  shadeIntensity: 0.1,
                  opacityFrom: 0.8,
                  opacityTo: 0.9,
                  stops: [0, 95, 100],
                },
              },
            });
          }
        }
      }

      if (Math.round(totalOther) !== 0) {
        l.push("Other");
        d.push({
          x: "Other",
          y: Math.round(totalOther / otherCount),
          fillColor: pastelColors[chart2.length % pastelColors.length], // Assign pastel color to "Other"
        });
      }
      setLocation(l);
      setData(d);
      dispatch(setSalarydashboard({ chart2success: false }));
    }
  }, [chart2success, chart2]);

  useEffect(() => {
    if (chart2loading) {
      setData(null);
      setLocation(null);
    }
  }, [chart2loading]);

  const options = {
    dataLabels: {
      enabled: false,
      formatter: function (val) {
        return `${customYAxisFormatter(val)}`;
      },
      style: {
        colors: ["#000000"],
      },
    },
    chart: {
      toolbar: {
        show: false,
      },
      type: "bar",
      height: 400,
      events: {
        mouseOver: (event, chartContext, config) => {
          if (isDemoUser) {
            event.preventDefault();
          }
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: true, // Set this to true for horizontal bars
        dataLabels: {
          enabled: !isDemoUser,
          position: "center", // Adjust the position of the data labels
        },
      },
    },
    yaxis: {
      categories: location, // Move categories to yaxis for horizontal bar chart
      show: !isDemoUser,
      labels: {
        show: !isDemoUser,
        style: {
          colors: isDemoUser ? "#cccccc" : "#000000",
        },
      },
    },
    xaxis: {
      labels: {
        show: !isDemoUser,
        formatter: customYAxisFormatter,
        style: {
          colors: "#000000",
        },
      },
      axisTicks: {
        show: true,
      },
    },
    tooltip: {
      enabled: !isDemoUser,
    },
    zoom: {
      enabled: !isDemoUser,
    },
    stroke: {
      width: 0,
    },
    colors: [isDemoUser ? "#cccccc" : undefined],
  };

  return (
    <div className={`graph z-depth-3 ${isDemoUser ? "disabled-chart" : ""}`}>
      {data?.length > 1 && location && !chart2error ? (
        <div className="graph-div">
          <span className="graph-heading">
            {`Median salary for "${common?.salaryDashboard?.searchRole}" jobs in ${common.salaryDashboard?.location} vs  across Australia`}
          </span>
          <ReactApexChart
            options={options}
            series={[{ name: "Job Salary", data: data }]}
            type="bar"
            height={300}
          />
        </div>
      ) : (
        <div className="graph-nodata-div">
          <h6 className="graph-heading">
            {"Median Salary in " +
              common.salaryDashboard?.location +
              " vs. Across Australia"}
          </h6>
          {chart2loading ? <LoaderComp /> : <span>No Data</span>}
        </div>
      )}
    </div>
  );
}
