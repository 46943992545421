import {
  GET_USER_ACTIVITY_SUCCESS,
  GET_USER_LIST_SUCCESS,
  SET_USER_ACTIVITY_REDUCER,
} from "../types/userActivityType";

const initState = {
  getUserListSuccess: false,
  userList: null,

  getUserActivitySuccess: false,
  userActivity: null,
};

const userActivityReducer = (state = initState, action) => {
  //   console.log(action);
  switch (action.type) {
    case SET_USER_ACTIVITY_REDUCER:
      return { ...state, ...action.payload };
    case GET_USER_LIST_SUCCESS:
      return { ...state, getUserListSuccess: true, userList: action.payload };
    case GET_USER_ACTIVITY_SUCCESS:
      return {
        ...state,
        getUserActivitySuccess: true,
        userActivity: action.payload,
      };
    default:
      return state;
  }
};

export default userActivityReducer;
