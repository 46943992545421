import React from "react";
import CustomLabel from "../../Customs/CustomLabel";
import CustomInput1 from "../../Customs/CustomInput1";
import CustomButton2 from "../../Customs/CustomButton2";
import CustomSelect from "../../Customs/CustomSelect";
import { useDispatch, useSelector } from "react-redux";
import { api_request, setCommon } from "../../../store/action/commonActions";
import { method_get, method_post } from "../../../config/config";
import {
  CHART1_ERROR,
  CHART1_LOADING,
  CHART1_SUCCESS,
  CHART2_ERROR,
  CHART2_LOADING,
  CHART2_SUCCESS,
  CHART3_ERROR,
  CHART3_LOADING,
  CHART3_SUCCESS,
  CHART4_ERROR,
  CHART4_LOADING,
  CHART4_SUCCESS,
  CHART5_ERROR,
  CHART5_LOADING,
  CHART5_SUCCESS,
  CHART6_ERROR,
  CHART6_LOADING,
  CHART6_SUCCESS,
  CHART7_ERROR,
  CHART7_LOADING,
  CHART7_SUCCESS,
  CHART8_ERROR,
  CHART8_LOADING,
  CHART8_SUCCESS,
  FETCH_COMPANY_ERROR,
  FETCH_COMPANY_LOADING,
  FETCH_COMPANY_SUCCESS,
} from "../../../store/types/salaryDashBoardReducerTypes";
import { experience, locations } from "../../../config/constants";

import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

export default function Filters() {
  const dispatch = useDispatch();
  const [searchType, setSearchType] = React.useState("");
  const user = useSelector((state) => state.auth?.user);

  const common = useSelector((state) => state.common);

  const chart1loading = useSelector(
    (state) => state.salaryDashboard?.chart1loading
  );
  const chart2loading = useSelector(
    (state) => state.salaryDashboard?.chart2loading
  );
  const chart3loading = useSelector(
    (state) => state.salaryDashboard?.chart3loading
  );
  const chart4loading = useSelector(
    (state) => state.salaryDashboard?.chart4loading
  );
  const chart5loading = useSelector(
    (state) => state.salaryDashboard?.chart5loading
  );
  const chart6loading = useSelector(
    (state) => state.salaryDashboard?.chart6loading
  );

  // useEffect(() => {
  //   dispatch(
  //     api_request(
  //       method_get,
  //       "/jobCategory",
  //       {},
  //       {},
  //       JOB_CATEGORY_FETCH_LOADING,
  //       JOB_CATEGORY_FETCH_SUCCESS,
  //       JOB_CATEGORY_FETCH_ERROR,
  //       user?.accessToken
  //     )
  //   );
  //   onSubmit();
  // }, []);

  const onChange = (e) => {
    const priority = {
      category: { value: 1, init: "", isDepend: true },
      role: { value: 2, init: "", isDepend: true },
      searchRole: { value: 2, init: "", isDepend: true },
      location: { value: 3, isDepend: false },
      company: { value: 4, init: [], isDepend: true },
    };
    let keys = Object.keys(priority);
    let haveChange = priority[e.target.id];
    let commonObj = { ...common.salaryDashboard };
    if (haveChange) {
      for (let i = 0; i < keys.length; i++) {
        if (e.target.id === keys[i]) {
          commonObj = {
            ...commonObj,
            [e.target.id]: e.target.value,
          };
        } else if (
          priority[keys[i]]["value"] >= haveChange["value"] &&
          priority[keys[i]].isDepend
        ) {
          commonObj = {
            ...commonObj,
            [keys[i]]: priority[keys[i]]["init"],
          };
        }
      }
    } else {
      commonObj = {
        ...commonObj,
        [e.target.id]: e.target.value,
      };
    }
    // console.log("obj", commonObj);
    dispatch(
      setCommon({
        salaryDashboard: commonObj,
      })
    );
    const companyFilterParam = ["category", "role", "searchRole", "location"];
    if (companyFilterParam.includes(e.target.id)) {
      if (e.target.value) {
        let filterVal = {
          category: "classification",
          role: "category",
          searchRole: "title",
          location: "location",
        };
        let body = {};
        for (let p = 0; p < companyFilterParam.length; p++) {
          if (e.target.id === companyFilterParam[p]) {
            body[filterVal[companyFilterParam[p]]] = e.target.value;
          } else {
            body[filterVal[companyFilterParam[p]]] =
              common?.salaryDashboard[companyFilterParam[p]];
          }
        }
        if (common?.salaryDashboard?.filterType) {
          delete body.category;
        } else {
          delete body.title;
        }
        dispatch(
          api_request(
            method_get,
            "/company",
            null,
            {
              filter: encodeURIComponent(JSON.stringify(body)),
            },
            FETCH_COMPANY_LOADING,
            FETCH_COMPANY_SUCCESS,
            FETCH_COMPANY_ERROR,
            user?.accessToken
          )
        );
      }
    }
  };

  const onSubmit = (e = null) => {
    e?.preventDefault();
    if (common.salaryDashboard?.skill?.split(",")?.length > 5) {
      alert("Maximum 5 skills are allowed");
    }
    if (e) {
      dispatch(
        api_request(
          method_post,
          "/searchLog",
          {
            role: common.salaryDashboard?.category?.trim(),
            subcategory: common.salaryDashboard?.searchRole?.trim(),
            skill1: common.salaryDashboard?.skill?.split(",")[0]?.trim(),
            skill2: common.salaryDashboard?.skill?.split(",")[1]?.trim(),
            skill3: common.salaryDashboard?.skill?.split(",")[2]?.trim(),
            skill4: common.salaryDashboard?.skill?.split(",")[3]?.trim(),
            skill5: common.salaryDashboard?.skill?.split(",")[4]?.trim(),
            location: common?.salaryDashboard?.location,
            factor: common?.salaryDashboard?.experience,
            company: common?.salaryDashboard?.company?.map((d, i) => {
              return d.value;
            }),
          },
          null,
          null,
          null,
          null,
          user?.accessToken
        )
      );
    }
    dispatch(
      api_request(
        method_get,
        "/chart1",
        null,
        {
          filter: encodeURIComponent(
            JSON.stringify({
              location: common?.salaryDashboard?.location,
              factor: common?.salaryDashboard?.experience,
              company: common?.salaryDashboard?.company?.map((d, i) => {
                return d.value.replace("'", "\\'");
              }),
              filterType: common?.salaryDashboard?.filterType
                ? "title"
                : "category",
              title: common?.salaryDashboard?.searchRole?.trim(),
              classification: common?.salaryDashboard?.category?.trim(),
              searchType: searchType,
            })
          ),
        },
        CHART1_LOADING,
        CHART1_SUCCESS,
        CHART1_ERROR,
        user?.accessToken
      )
    );
    dispatch(
      api_request(
        method_get,
        "/chart2",
        null,
        {
          filter: encodeURIComponent(
            JSON.stringify({
              location: common?.salaryDashboard?.location,
              factor: common?.salaryDashboard?.experience,
              company: common?.salaryDashboard?.company?.map((d, i) => {
                return d.value.replace("'", "\\'");
              }),
              filterType: common?.salaryDashboard?.filterType
                ? "title"
                : "category",
              title: common?.salaryDashboard?.searchRole?.trim(),
              classification: common?.salaryDashboard?.category?.trim(),
              searchType: searchType,
            })
          ),
        },
        CHART2_LOADING,
        CHART2_SUCCESS,
        CHART2_ERROR,
        user?.accessToken
      )
    );
    dispatch(
      api_request(
        method_get,
        "/chart4",
        null,
        {
          filter: encodeURIComponent(
            JSON.stringify({
              location: common?.salaryDashboard?.location,
              factor: common?.salaryDashboard?.experience,
              company: common?.salaryDashboard?.company?.map((d, i) => {
                return d.value.replace("'", "\\'");
              }),
              filterType: common?.salaryDashboard?.filterType
                ? "title"
                : "category",
              title: common?.salaryDashboard?.searchRole?.trim(),
              classification: common?.salaryDashboard?.category?.trim(),
              searchType: searchType,
            })
          ),
        },
        CHART4_LOADING,
        CHART4_SUCCESS,
        CHART4_ERROR,
        user?.accessToken
      )
    );
    dispatch(
      api_request(
        method_get,
        "/chart6",
        null,
        {
          filter: encodeURIComponent(
            JSON.stringify({
              location: common?.salaryDashboard?.location,
              factor: common?.salaryDashboard?.experience,
              company: common?.salaryDashboard?.company?.map((d, i) => {
                return d.value.replace("'", "\\'");
              }),
              filterType: common?.salaryDashboard?.filterType
                ? "title"
                : "category",
              title: common?.salaryDashboard?.searchRole?.trim(),
              classification: common?.salaryDashboard?.category?.trim(),
              searchType: searchType,
            })
          ),
        },
        CHART6_LOADING,
        CHART6_SUCCESS,
        CHART6_ERROR,
        user?.accessToken
      )
    );
    dispatch(
      api_request(
        method_get,
        "/chart7",
        null,
        {
          filter: encodeURIComponent(
            JSON.stringify({
              location: common?.salaryDashboard?.location,
              factor: common?.salaryDashboard?.experience,
              company: common?.salaryDashboard?.company?.map((d, i) => {
                return d.value.replace("'", "\\'");
              }),
              filterType: common?.salaryDashboard?.filterType
                ? "title"
                : "category",
              title: common?.salaryDashboard?.searchRole?.trim(),
              classification: common?.salaryDashboard?.category?.trim(),
              searchType: searchType,
            })
          ),
        },
        CHART7_LOADING,
        CHART7_SUCCESS,
        CHART7_ERROR,
        user?.accessToken
      )
    );
    dispatch(
      api_request(
        method_get,
        "/chart8",
        null,
        {
          filter: encodeURIComponent(
            JSON.stringify({
              location: common?.salaryDashboard?.location,
              factor: common?.salaryDashboard?.experience,
              company: common?.salaryDashboard?.company?.map((d, i) => {
                return d.value.replace("'", "\\'");
              }),
              filterType: common?.salaryDashboard?.filterType
                ? "title"
                : "category",
              title: common?.salaryDashboard?.searchRole?.trim(),
              classification: common?.salaryDashboard?.category?.trim(),
              searchType: searchType,
            })
          ),
        },
        CHART8_LOADING,
        CHART8_SUCCESS,
        CHART8_ERROR,
        user?.accessToken
      )
    );
    if (
      common.salaryDashboard?.skill &&
      common.salaryDashboard?.skill.trim() !== ""
    ) {
      dispatch(
        api_request(
          method_get,
          "/chart5",
          null,
          {
            filter: encodeURIComponent(
              JSON.stringify({
                skills: common.salaryDashboard?.skill
                  ?.split(",")
                  .map((d, di) => {
                    return d.trim();
                  }),
                location: common?.salaryDashboard?.location,
                factor: common?.salaryDashboard?.experience,
                company: common?.salaryDashboard?.company?.map((d, i) => {
                  return d.value.replace("'", "\\'");
                }),
                filterType: common?.salaryDashboard?.filterType
                  ? "title"
                  : "category",
                title: common?.salaryDashboard?.searchRole?.trim(),
                classification: common?.salaryDashboard?.category?.trim(),
                searchType: searchType,
              })
            ),
          },
          CHART5_LOADING,
          CHART5_SUCCESS,
          CHART5_ERROR,
          user?.accessToken
        )
      );
      dispatch(
        api_request(
          method_get,
          "/chart3",
          null,
          {
            filter: encodeURIComponent(
              JSON.stringify({
                skills: common.salaryDashboard?.skill
                  ?.split(",")
                  .map((d, di) => {
                    return d.trim();
                  }),
                location: common?.salaryDashboard?.location,
                factor: common?.salaryDashboard?.experience,
                company: common?.salaryDashboard?.company?.map((d, i) => {
                  return d.value.replace("'", "\\'");
                }),
                filterType: common?.salaryDashboard?.filterType
                  ? "title"
                  : "category",
                title: common?.salaryDashboard?.searchRole?.trim(),
                classification: common?.salaryDashboard?.category?.trim(),
                searchType: searchType,
              })
            ),
          },
          CHART3_LOADING,
          CHART3_SUCCESS,
          CHART3_ERROR,
          user?.accessToken
        )
      );
    }
  };

  const clearSearch = (e) => {
    e.preventDefault();
    dispatch(
      setCommon({
        salaryDashboard: {
          experience: 1,
          location: "Sydney NSW",
          filterType: true,
        },
      })
    );
  };

  return (
    <div id="filter-container">
      <form id="filter" onSubmit={onSubmit}>
        <div className="filter-section filter z-depth-3">
          <div className="row">
            <div className="col s12 l4">
              <CustomLabel>Sector</CustomLabel>
              <CustomInput1
                id="category"
                onChange={onChange}
                placeholder="e.g. Energy"
                value={common.salaryDashboard?.category}
              />
            </div>
            <div className="col s12 l4">
              <CustomLabel required={true}>Title</CustomLabel>
              <CustomInput1
                id="searchRole"
                disabled={!common.salaryDashboard?.filterType}
                placeholder="e.g. Business Analyst"
                value={common.salaryDashboard?.searchRole}
                onChange={onChange}
                required={true}
              />
            </div>
            <div className="col s12 l4">
              <CustomLabel required={true}>Location</CustomLabel>
              <CustomSelect
                id="location"
                value={common.salaryDashboard?.location}
                onChange={onChange}
                data={[
                  { label: "Select", value: "", disabled: true },
                  ...locations.map((d, i) => {
                    return { label: d, value: d };
                  }),
                ]}
              />
            </div>
            {/* <div className="row">
              <div className="col s12 l12">
                <CustomLabel>Company</CustomLabel>
                <Select
                  isMulti={true}
                  onChange={onSelectCompany}
                  options={company?.map((d, i) => {
                    return { label: d.company, value: d.company };
                  })}
                  value={common.salaryDashboard.company}
                  styles={{ height: "122px" }}
                />
              </div>
            </div> */}
            <div className="col s12 l6">
              <CustomLabel required={true}>Experience</CustomLabel>
              <CustomSelect
                id="experience"
                value={common.salaryDashboard?.experience}
                onChange={onChange}
                components={animatedComponents}
                data={[
                  { label: "Select", value: "", disabled: true },
                  ...experience.map((d, i) => {
                    return { label: d.label, value: d.value };
                  }),
                ]}
              />
            </div>
            <div className="col s12 l6">
              <CustomLabel>Skills</CustomLabel>
              <CustomInput1
                id="skill"
                placeholder="e.g. JIRA, Agile, Confluence, BPMN"
                value={common.salaryDashboard?.skill}
                onChange={onChange}
              />
            </div>
            {/* <div id="clear-filter-button-holder" className="col s12 l3 right">
            <CustomButton2 id="filter-button" onClick={onSubmit}>
              Show Results
            </CustomButton2>
          </div> */}
            <div className="col s12 l12 right" id="filter-button-holder">
              <CustomButton2 id="clear-filter-button" onClick={clearSearch}>
                Clear Search
              </CustomButton2>
              <CustomButton2
                id="filter-button"
                type="submit"
                form="filter"
                onClick={(e) => setSearchType("exact")}
                disabled={
                  chart1loading ||
                  chart2loading ||
                  chart3loading ||
                  chart4loading ||
                  chart5loading ||
                  chart6loading
                }
              >
                Search for Exact Title
              </CustomButton2>
              <CustomButton2
                id="filter-button"
                type="submit"
                form="filter"
                onClick={(e) => setSearchType("keywords")}
                disabled={
                  chart1loading ||
                  chart2loading ||
                  chart3loading ||
                  chart4loading ||
                  chart5loading ||
                  chart6loading
                }
              >
                Search by Keywords
              </CustomButton2>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
