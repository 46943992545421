import React, { useEffect, useState } from "react";
import CustomInput3 from "../Customs/CustomInput3";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Layouts/Loader";
import { api_request } from "../../store/action/commonActions";
import {
  FEEDBACK_SENT_ERROR,
  FEEDBACK_SENT_LOADING,
  FEEDBACK_SENT_SUCCESS,
} from "../../store/types/otherTypes";
import swal from "sweetalert";
import { method_post } from "../../config/config";
import CustomButton1 from "../Customs/CustomButton1";
import { setOtherReducer } from "../../store/action/otherAction";
import Navbar from "../Layouts/Navbar";
import Footer from "../Layouts/Footer";
import CustomSelect from "../Customs/CustomSelect";
import { contactTypes } from "../../config/constants";

export default function ContactUs() {
  const dispatch = useDispatch();

  const feedbackSentLoading = useSelector(
    (state) => state.other?.feedbackSentLoading
  );
  const feedbackSentSuccess = useSelector(
    (state) => state.other?.feedbackSentSuccess
  );
  const feedbackSentError = useSelector(
    (state) => state.other?.feedbackSentError
  );
  const feedbackSentMsg = useSelector((state) => state.other?.feedbackSentMsg);

  const [localState, setLocalState] = useState({ type: "Both" });

  useEffect(() => {
    if (feedbackSentSuccess) {
      swal("Success", feedbackSentMsg, "success");
      dispatch(setOtherReducer({ feedbackSentSuccess: false }));
    }
    if (feedbackSentError) {
      swal("Oops!", feedbackSentMsg, "error");
      dispatch(setOtherReducer({ feedbackSentError: false }));
    }
  }, [feedbackSentSuccess, feedbackSentError]);

  const onChange = (e) => {
    setLocalState({
      ...localState,
      [e.target.id]: e.target.value,
    });
  };

  const sendFeedback = (e) => {
    e?.preventDefault();
    dispatch(
      api_request(
        method_post,
        "/contactUs",
        localState,
        null,
        FEEDBACK_SENT_LOADING,
        FEEDBACK_SENT_SUCCESS,
        FEEDBACK_SENT_ERROR
      )
    );
  };

  if (feedbackSentLoading) {
    return <Loader />;
  }

  return (
    <div>
      <Navbar />
      <div id="home-div-third" className="row">
        <div className="container center">
          <div className="conact-card z-depth-3">
            <h5 style={{ color: "black" }}>Contact Us</h5>
            <h6 style={{ color: "black" }}>
              Drop in a line and we will get back to you
            </h6>
            <form id="contact-peopleai" onSubmit={sendFeedback}>
              <div className="row">
                <div className="col s6">
                  <CustomInput3
                    id="name"
                    label="YOUR NAME"
                    required={true}
                    value={localState?.name}
                    onChange={onChange}
                  />
                </div>
                <div className="col s6">
                  <CustomInput3
                    id="email"
                    type="email"
                    label="YOUR EMAIL"
                    required={true}
                    value={localState?.email}
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col s12 l6">
                  <CustomInput3
                    id="subject"
                    label="SUBJECT"
                    required={true}
                    value={localState?.subject}
                    onChange={onChange}
                  />
                </div>
                <div className="col s12 l6">
                  <CustomSelect
                    id="type"
                    value={localState.type}
                    required={true}
                    label="Contact For"
                    data={[
                      ...contactTypes.map((d, i) => {
                        return { label: d, value: d };
                      }),
                    ]}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col s12">
                  <label>YOUR MESSAGE (OPTIONAL)</label>
                  <textarea
                    id="message"
                    onChange={onChange}
                    value={localState?.message}
                  />
                </div>
              </div>
              <div className="row">
                <CustomButton1 type="submit">Submit</CustomButton1>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
