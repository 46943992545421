import React from "react";
import Navbar from "../Layouts/Navbar";
import Footer from "../Layouts/Footer";
import CustomButton1 from "../Customs/CustomButton1";
import { useNavigate } from "react-router-dom";
import { contact_us_path } from "../../config/config";

export default function Demo() {
  const navigate = useNavigate();

  const onContact = () => {
    navigate(contact_us_path);
  };

  return (
    <>
      <Navbar />
      <div id="demo">
        <video id="demo-vid" controls>
          <source src="videos/screen-capture.webm" type="video/webm" />
          Your browser does not support the video tag.
        </video>
        <br />
        <CustomButton1 style={{ whiteSpace: "pre-line" }} onClick={onContact}>
          Contact Us
        </CustomButton1>
      </div>
      <Footer />
    </>
  );
}
