import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { useDispatch, useSelector } from "react-redux";
import { setSalarydashboard } from "../../../store/action/salaryDashboardAction";
import LoaderComp from "../../Layouts/LoaderComp";
import { pastelColors } from "../../../config/constants";

export default function JobPercentPie() {
  const dispatch = useDispatch();

  const chart1 = useSelector((state) => state.salaryDashboard?.chart1);
  // eslint-disable-next-line
  const chart1success = useSelector(
    (state) => state.salaryDashboard?.chart1success
  );

  const chart1loading = useSelector(
    (state) => state.salaryDashboard?.chart1loading
  );

  const [data, setData] = useState([["Company", "Job Offers"]]);

  useEffect(() => {
    if (chart1) {
      let d = [["Company", "Job Offers"]];
      let totalOther = 0;
      let otherCount = 0;
      for (let i = 0; i < chart1?.length; i++) {
        if (chart1[i]?.location?.toLowerCase()?.includes("other")) {
          totalOther = totalOther + Number(chart1[i]?.noOfJobs);
          otherCount++;
        } else {
          d.push([chart1[i]?.location, chart1[i]?.noOfJobs]);
        }
      }
      d.push(["Other", Math.round(totalOther / otherCount)]);
      setData(d);
      dispatch(setSalarydashboard({ chart1success: false }));
    }
    //eslint-disable-next-line
  }, [chart1]);

  const options = {
    titleTextStyle: { color: "black" },
    legend: {
      textStyle: {
        color: "black",
        fontSize: 8,
      },
    },
    is3D: true,
    backgroundColor: "transparent",
    fontColor: "black",
    enableInteractivity: false,
    colors: pastelColors, // Apply pastel colors here
  };

  useEffect(() => {
    if (chart1loading) {
      setData(null);
    }
  }, [chart1loading]);

  return (
    <div className="graph  z-depth-3">
      {data?.length > 1 ? (
        <div className="graph-div">
          <span className="graph-heading">
            Percentage of jobs advertised across Australia
          </span>
          <Chart
            chartType="PieChart"
            data={data}
            options={options}
            width={"100%"}
            height={"300px"}
          />
        </div>
      ) : (
        <div className="graph-nodata-div">
          <h6 className="graph-heading">
            Percentage of jobs advertised across Australia
          </h6>
          {chart1loading ? <LoaderComp /> : <span>No Data</span>}
        </div>
      )}
    </div>
  );
}
