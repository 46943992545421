import React, { useEffect } from "react";
import CustomInput2 from "../Customs/CustomInput2";
import CustomLabel from "../Customs/CustomLabel";
import CustomButton1 from "../Customs/CustomButton1";
import CustomLink from "../Customs/CustomLink";
import { Formik } from "formik";
import { loginSchema } from "../../utils/formValidationSchemas";
import Navbar from "../Layouts/Navbar";
import { api_request } from "../../store/action/commonActions";
import {
  method_post,
  registration_path,
  reset_password_path,
  salary_dashboard_path,
} from "../../config/config";
import {
  LOGIN_ERROR,
  LOGIN_LOADING,
  LOGIN_SUCCESS,
} from "../../store/types/authTypes";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../Layouts/Loader";
import swal from "sweetalert";
import { setAuthreducer } from "../../store/action/authAction";
import CustomPassword from "../Customs/CustomPassword";
import Footer from "../Layouts/Footer";
import { setSalarydashboard } from "../../store/action/salaryDashboardAction";

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth?.user);
  const authLoading = useSelector((state) => state.auth?.authLoading);
  const authError = useSelector((state) => state.auth?.authError);
  const authErrorMessage = useSelector((state) => state.auth?.authErrorMessage);

  useEffect(() => {
    if (authError) {
      swal("Oops!", authErrorMessage || "Login Error", "error");
      dispatch(setAuthreducer({ authError: false, UNAUTHORIZED_USER: null }));
    }
  }, [authError]);

  const login = (values) => {
    dispatch(
      api_request(
        method_post,
        "/login",
        {
          userName: values?.userId,
          password: values?.password,
        },
        null,
        LOGIN_LOADING,
        LOGIN_SUCCESS,
        LOGIN_ERROR
      )
    );
  };

  const loginDemo = () => {
    dispatch(
      api_request(
        method_post,
        "/login-demo",
        {
          userName: "demo",
          password: "demo",
        },
        null,
        LOGIN_LOADING,
        LOGIN_SUCCESS,
        LOGIN_ERROR
      )
    );
  };

  if (authLoading) {
    return <Loader />;
  }

  if (user) {
    dispatch(
      setSalarydashboard({
        chart1loading: false,
        chart2loading: false,
        chart3loading: false,
        chart4loading: false,
        chart5loading: false,
        chart6loading: false,
      })
    );
    navigate(salary_dashboard_path);
  }

  return (
    <>
      <Navbar />
      <div className="row">
        <div className="col l6 s12 auth-left">
          <Formik
            initialValues={{ userId: "", password: "" }}
            validationSchema={loginSchema}
            onSubmit={(values, actions) => {
              login(values);
            }}
          >
            {(formikProps) => (
              <div className="center" id="login">
                <div className="row">
                  <span style={{ color: "black", fontSize: 16 }}>Login</span>
                  <i
                    className="material-icons"
                    style={{
                      color: "black",
                      transform: "translate(5px, 6px)",
                    }}
                  >
                    account_circle
                  </i>
                  <CustomLabel required={true}>Username or Email</CustomLabel>
                  <CustomInput2
                    id="userId"
                    onBlur={formikProps.handleBlur("userId")}
                    onChange={formikProps.handleChange("userId")}
                    value={formikProps.values.userId}
                    formikProps={formikProps}
                  />
                  <CustomLabel required={true}>Password</CustomLabel>
                  <CustomPassword
                    id="password"
                    onBlur={formikProps.handleBlur("password")}
                    onChange={formikProps.handleChange("password")}
                    value={formikProps.values.password}
                    formikProps={formikProps}
                  />
                </div>
                <div className="row ">
                  <CustomButton1
                    type="button"
                    style={{ marginRight: "10px" }}
                    onClick={formikProps.handleSubmit}
                  >
                    Login
                  </CustomButton1>
                  <CustomButton1
                    type="button"
                    id="secondary-button"
                    onClick={() => {
                      formikProps.setSubmitting(true);
                      loginDemo();
                    }}
                  >
                    View Demo
                  </CustomButton1>
                </div>
                <div className="row">
                  <CustomLink to={reset_password_path}>
                    Lost Password?
                  </CustomLink>
                </div>
                <div className="row">
                  <CustomLink to={registration_path}>
                    Register now if not registered.
                  </CustomLink>
                </div>
              </div>
            )}
          </Formik>
        </div>
        <div className="col l6 auth-right hide-on-med-and-down"></div>
      </div>
      <Footer />
    </>
  );
}
