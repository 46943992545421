import React from "react";

export default function CustomLabel(props) {
  return (
    <div style={{ textAlign: "left", marginBottom: "8px" }}>
      <span
        style={{ color: props.color || "black", fontWeight: 500, fontSize: 14 }}
      >
        {props.children}
      </span>
      {props.required && (
        <span style={{ color: "red", fontSize: 15, fontWeight: 900 }}>*</span>
      )}
    </div>
  );
}
