import { locations_color } from "../config/constants";

export const getSkillByData = (data, location) => {
  let result = {};
  for (let i = 0; i < data.length; i++) {
    let skill = data[i]?.skill?.trim();
    if (
      data[i]?.location === location ||
      data[i]?.location?.includes(location)
    ) {
      if (result[skill]) {
        result[skill]["total"] = Number(data[i]?.median);
        result[skill]["count"] += 1;
      } else if (skill?.trim() !== "") {
        result[skill] = {
          total: Number(data[i]?.median),
          count: 1,
        };
      }
    }
  }
  return result;
};

export const getLastMonths = (no = 6) => {
  const date = new Date();
  const y = date.getFullYear();
  const m = date.getMonth() + 1;
  let result = [];
  for (let i = 0; i < no; i++) {
    result.push({
      month: m - i <= 0 ? 12 - (m - i) : m - i,
      year: m - i <= 0 ? y - 1 : y,
    });
  }
  return result.reverse();
};

export const yearLabelHelper = (year) => {
  const y = String(year);
  return y[2] + y[3];
};

export const customYAxisFormatter = (value) => {
  if (value >= 1000) {
    value = value / 1000;
    return "$" + value + "k";
  } else {
    return "$" + value;
  }
};

export const generateRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const getnRandomColor = (n) => {
  let result = [];
  for (let i = 0; i < n; i++) {
    let color = generateRandomColor();
    let index = result.findIndex((f) => f === color);
    if (index === -1) {
      result.push(color);
    } else {
      i--;
    }
  }
  return result;
};

export const locationWiseColor = (location) => {
  let colors = [];

  for (let i = 0; i < location?.length; i++) {
    colors.push(
      locations_color[location[i]?.toLowerCase()?.trim()] || "#8f8f8f"
    );
  }

  return colors;
};
