import React from "react";

export default function CustomInput1(props) {
  const { formikProps, id, value, ...restProps } = props;
  return (
    <>
      <input
        id={id}
        value={value ? value : ""}
        {...restProps}
        style={{
          backgroundColor: "#ffffff",
          borderRadius: "2px",
          paddingLeft: "5px",
        }}
      />
      {formikProps?.touched[id] && formikProps?.errors[id] ? (
        <span className="error-text">
          {formikProps?.touched[id] && formikProps?.errors[id]}
        </span>
      ) : null}
    </>
  );
}
