import React, { useEffect } from "react";
import CustomLabel from "../Customs/CustomLabel";
import { Formik } from "formik";
import CustomInput2 from "../Customs/CustomInput2";
import { changePasswordSchema } from "../../utils/formValidationSchemas";
import Navbar from "../Layouts/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { api_request } from "../../store/action/commonActions";
import { method_post } from "../../config/config";
import Loader from "../Layouts/Loader";
import swal from "sweetalert";
import { setAuthreducer } from "../../store/action/authAction";
import CustomButton2 from "../Customs/CustomButton2";
import CustomPassword from "../Customs/CustomPassword";
import {
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_LOADING,
  CHANGE_PASSWORD_SUCCESS,
} from "../../store/types/authTypes";
import Footer from "../Layouts/Footer";

export default function ChangePassword() {
  const dispatch = useDispatch();

  const queryParams = new URLSearchParams(window.location.search);
  const userName = queryParams.get("userName");

  const changePasswordLoading = useSelector(
    (state) => state.auth?.changePasswordLoading
  );
  const changePasswordSuccess = useSelector(
    (state) => state.auth?.changePasswordSuccess
  );
  const changePasswordError = useSelector(
    (state) => state.auth?.changePasswordError
  );
  const changePasswordMessage = useSelector(
    (state) => state.auth?.changePasswordMessage
  );

  useEffect(() => {
    if (changePasswordSuccess) {
      swal("Success", changePasswordMessage, "success");
      dispatch(setAuthreducer({ changePasswordSuccess: false }));
    }
    if (changePasswordError) {
      swal("Oops!", changePasswordMessage, "error");
      dispatch(setAuthreducer({ changePasswordError: false }));
    }
  }, [changePasswordSuccess, changePasswordError]);

  const submit = (values) => {
    dispatch(
      api_request(
        method_post,
        "/changePassword",
        {
          userName: userName,
          password: values?.password,
          otp: values?.otp,
        },
        null,
        CHANGE_PASSWORD_LOADING,
        CHANGE_PASSWORD_SUCCESS,
        CHANGE_PASSWORD_ERROR
      )
    );
  };

  if (changePasswordLoading) {
    return <Loader />;
  }

  return (
    <>
      <Navbar />
      <div className="row">
        <div className="col l6 s12 auth-left">
          <div className="center" id="changepassword">
            <div className="row">
              <Formik
                initialValues={{ password: "", confirmPassword: "", otp: "" }}
                validationSchema={changePasswordSchema}
                onSubmit={(values, actions) => {
                  submit(values);
                }}
              >
                {(formikProps) => (
                  <>
                    <div className="row">
                      <CustomLabel required={true}>Password</CustomLabel>
                      <CustomPassword
                        id="password"
                        onBlur={formikProps.handleBlur("password")}
                        onChange={formikProps.handleChange("password")}
                        value={formikProps.values.password}
                        formikProps={formikProps}
                      />
                      <CustomLabel required={true}>
                        Confirm Password
                      </CustomLabel>
                      <CustomPassword
                        id="confirmPassword"
                        onBlur={formikProps.handleBlur("confirmPassword")}
                        onChange={formikProps.handleChange("confirmPassword")}
                        value={formikProps.values.confirmPassword}
                        formikProps={formikProps}
                      />
                      <CustomLabel required={true}>OTP</CustomLabel>
                      <CustomInput2
                        id="otp"
                        onBlur={formikProps.handleBlur("otp")}
                        onChange={formikProps.handleChange("otp")}
                        value={formikProps.values.otp}
                        formikProps={formikProps}
                      />
                    </div>
                    <div className="row">
                      <CustomButton2
                        type="button"
                        onClick={formikProps.handleSubmit}
                      >
                        Reset Password
                      </CustomButton2>
                    </div>
                  </>
                )}
              </Formik>
            </div>
          </div>
        </div>
        <div className="col l6 auth-right hide-on-med-and-down"></div>
      </div>
      <Footer />
    </>
  );
}
