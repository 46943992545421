import React from "react";

export default function CommentCard({ data }) {
  return (
    <div className="comment-holder">
      <q className="comment">{data?.comment}</q>
      <br />
      <span>{`-${data?.User?.name}`}</span>
    </div>
  );
}
