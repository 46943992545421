import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSalarydashboard } from "../../../store/action/salaryDashboardAction";
import ReactApexChart from "react-apexcharts";
import LoaderComp from "../../Layouts/LoaderComp";
import { customYAxisFormatter } from "../../../utils/salaryDashboardDataHeler";
import { pastelColors } from "../../../config/constants"; // Import pastel colors

const calculatePercentile = (sortedData, percentile) => {
  const index = (percentile / 100) * sortedData.length;
  if (index % 1 === 0) {
    return (
      (sortedData[index - 1]?.average_salary +
        sortedData[index]?.average_salary) /
      2
    );
  } else {
    return sortedData[Math.floor(index)]?.average_salary;
  }
};

export default function MedianSalaryGauge({ role }) {
  const dispatch = useDispatch();
  const isDemoUser = role === "demo";

  const [data, setData] = useState(null);
  const [results, setResults] = useState({
    median: null,
    percentile25: null,
    percentile75: null,
  });

  const chart4 = useSelector((state) => state.salaryDashboard?.chart4);
  const chart4loading = useSelector(
    (state) => state.salaryDashboard?.chart4loading
  );
  const chart4success = useSelector(
    (state) => state.salaryDashboard?.chart4success
  );
  const chart4error = useSelector(
    (state) => state.salaryDashboard?.chart4error
  );
  const common = useSelector((state) => state.common);

  useEffect(() => {
    if (chart4) {
      const sortedData = [...chart4].sort(
        (a, b) => a?.average_salary - b?.average_salary
      );

      const median = calculatePercentile(sortedData, 50);
      const percentile25 = calculatePercentile(sortedData, 25);
      const percentile75 = calculatePercentile(sortedData, 75);

      setResults({
        median,
        percentile25,
        percentile75,
      });
    }
  }, [chart4, chart4success]);

  const options = {
    dataLabels: {
      enabled: !isDemoUser,
      formatter: function (val) {
        return `${customYAxisFormatter(val)}`;
      },
      style: {
        colors: ["#000000"],
      },
    },
    chart: {
      toolbar: {
        show: false,
      },
      type: "bar",
      height: 400,
      events: {
        mouseOver: (event, chartContext, config) => {
          if (isDemoUser) {
            event.preventDefault();
          }
        },
      },
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: !isDemoUser,
        },
      },
    },
    fill: {
      type: "solid",
      gradient: {
        shadeIntensity: 0.1,
        opacityFrom: 0.8,
        opacityTo: 0.9,
        stops: [0, 95, 100],
        shade: isDemoUser ? "#cccccc" : undefined, // Gray out the gradient if demo user
      },
    },
    yaxis: {
      labels: {
        show: !isDemoUser,
        formatter: customYAxisFormatter,
        style: {
          colors: "#000000",
        },
      },
      axisTicks: {
        show: true,
      },
    },
    xaxis: {
      categories: ["25th percentile", "Median", "75th percentile"],
      labels: {
        show: !isDemoUser,
        style: {
          colors: "#000000",
        },
      },
    },
    tooltip: {
      enabled: !isDemoUser,
    },
    zoom: {
      enabled: !isDemoUser,
    },
    stroke: {
      width: isDemoUser ? 0 : 0, // Adjust stroke width for demo users
    },
    colors: [
      isDemoUser ? "#cccccc" : pastelColors[0], // Pastel color for 25th percentile
      isDemoUser ? "#cccccc" : pastelColors[1], // Pastel color for Median
      isDemoUser ? "#cccccc" : pastelColors[2], // Pastel color for 75th percentile
    ], // Apply pastel colors here
  };

  useEffect(() => {
    if (chart4) {
      let data = [];
      if (
        chart4 &&
        results?.median &&
        results?.percentile25 &&
        results?.percentile75
      ) {
        data.push({
          x: "25th percentile",
          y: results.percentile25 ? Number(results.percentile25) : 0,
          fillColor: pastelColors[0], // Set pastel color for 25th percentile
        });
        data.push({
          x: "Median",
          y: results.median ? Number(results.median) : 0,
          fillColor: pastelColors[1], // Set pastel color for Median
        });
        data.push({
          x: "75th percentile",
          y: results.percentile75 ? Number(results.percentile75) : 0,
          fillColor: pastelColors[2], // Set pastel color for 75th percentile
        });
        setData(data);
      }
      dispatch(setSalarydashboard({ chart4success: false }));
    }
  }, [chart4success, chart4, results]);

  useEffect(() => {
    if (chart4loading) {
      setData(null);
    }
  }, [chart4loading]);

  return (
    <div className={`graph z-depth-3 ${isDemoUser ? "disabled-chart" : ""}`}>
      {data?.length === 3 &&
      data[0].y !== null &&
      data[0].y !== undefined &&
      data[0].y !== 0 &&
      data[1].y !== null &&
      data[1].y !== undefined &&
      data[1].y !== 0 &&
      data[2].y !== null &&
      data[2].y !== undefined &&
      data[2].y !== 0 &&
      !chart4error ? (
        <div className="graph-div">
          <span className="graph-heading">
            {`25th-median-75th-percentile salary for "${common?.salaryDashboard?.searchRole}" jobs in ${common.salaryDashboard?.location}`}
          </span>
          <ReactApexChart
            options={options}
            series={[{ name: "Job Salary", data: data }]}
            type="bar"
            height={300}
          />
        </div>
      ) : (
        <div className="graph-nodata-div">
          <h6 className="graph-heading">
            {"25th-Median-75th-percentile Salary in " +
              common.salaryDashboard?.location}
          </h6>
          {chart4loading ? <LoaderComp /> : <span>No Data</span>}
        </div>
      )}
    </div>
  );
}
