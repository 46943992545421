export const VERIFY_USER = "VERIFY_USER";
export const VERIFY_USER_LOADING = "VERIFY_USER_LOADING";
export const VERIFY_USER_SUCCESS = "VERIFY_USER_SUCCESS";
export const VERIFY_USER_ERROR = "VERIFY_USER_ERROR";

export const ACTIVATE_USER = "ACTIVATE_USER";
export const ACTIVATE_USER_LOADING = "ACTIVATE_USER_LOADING";
export const ACTIVATE_USER_SUCCESS = "ACTIVATE_USER_SUCCESS";
export const ACTIVATE_USER_ERROR = "ACTIVATE_USER_ERROR";

export const DEACTIVATE_USER = "DEACTIVATE_USER";
export const DEACTIVATE_USER_LOADING = "DEACTIVATE_USER_LOADING";
export const DEACTIVATE_USER_SUCCESS = "DEACTIVATE_USER_SUCCESS";
export const DEACTIVATE_USER_ERROR = "DEACTIVATE_USER_ERROR";

export const RENEW_USER = "RENEW_USER";
export const RENEW_USER_LOADING = "RENEW_USER_LOADING";
export const RENEW_USER_SUCCESS = "RENEW_USER_SUCCESS";
export const RENEW_USER_ERROR = "RENEW_USER_ERROR";

export const SET_ADMIN_ACTIVITY_REDUCER = "SET_ADMIN_ACTIVITY_REDUCER";
