import React from "react";
import LoaderComp from "./LoaderComp";

export default function Loader() {
  return (
    <div id="loader">
      <LoaderComp />
    </div>
  );
}
