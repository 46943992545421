import {
  FEEDBACK_SENT_ERROR,
  FEEDBACK_SENT_LOADING,
  FEEDBACK_SENT_SUCCESS,
  SET_OTHER_REDUCER,
} from "../types/otherTypes";

const initState = {
  feedbackSentLoading: false,
  feedbackSentSuccess: false,
  feedbackSentError: false,
  feedbackSentMsg: null,
};

const otherReducer = (state = initState, action) => {
  //   console.log(action);
  switch (action.type) {
    case FEEDBACK_SENT_LOADING:
      return {
        ...state,
        feedbackSentLoading: true,
        feedbackSentSuccess: false,
        feedbackSentError: false,
        feedbackSentMsg: null,
      };
    case FEEDBACK_SENT_SUCCESS:
      return {
        ...state,
        feedbackSentLoading: false,
        feedbackSentSuccess: true,
        feedbackSentError: false,
        feedbackSentMsg: action.message || "Feedback sent",
      };
    case FEEDBACK_SENT_ERROR:
      return {
        ...state,
        feedbackSentLoading: false,
        feedbackSentSuccess: false,
        feedbackSentError: true,
        feedbackSentMsg: action.message || "Error to sent feedback",
      };
    case SET_OTHER_REDUCER:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default otherReducer;
