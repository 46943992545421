import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { api_request } from "../../store/action/commonActions";
import { login_path, method_get } from "../../config/config";
import {
  GET_USER_ACTIVITY_ERROR,
  GET_USER_ACTIVITY_LOADING,
  GET_USER_ACTIVITY_SUCCESS,
} from "../../store/types/userActivityType";
import Navbar from "../Layouts/Navbar";
import Footer from "../Layouts/Footer";
import { getDateString } from "../../utils/common";

export default function UserActivityLog(props) {
  const { state = {} } = useLocation();

  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth?.user);
  const userActivity = useSelector((state) => state.userActivity?.userActivity);

  useEffect(() => {
    dispatch(
      api_request(
        method_get,
        "/userActivity/" + state?.id,
        null,
        null,
        GET_USER_ACTIVITY_LOADING,
        GET_USER_ACTIVITY_SUCCESS,
        GET_USER_ACTIVITY_ERROR,
        user?.accessToken
      )
    );
  }, []);

  if (!user) {
    return <Navigate to={login_path} />;
  }

  return (
    <>
      <Navbar />
      <div id="userActivity">
        <div id="userActivity-card">
          {userActivity && (
            <table className="table responsive-table centered">
              <thead>
                <tr>
                  <th>LOGIN</th>
                  <th>LOGOUT</th>
                  <th>SECTOR</th>
                  <th>TITLE</th>
                  <th>LOCATION</th>
                  <th>FACTOR</th>
                  <th>SKILL-1</th>
                  <th>SKILL-2</th>
                  <th>SKILL-3</th>
                  <th>SKILL-4</th>
                  <th>SKILL-5</th>
                </tr>
              </thead>
              <tbody>
                {userActivity.map((d, i) => (
                  <tr key={i}>
                    <td>{d.login ? getDateString(new Date(d.login)) : "-"}</td>
                    <td>
                      {d.logout ? getDateString(new Date(d.logout)) : "-"}
                    </td>
                    <td>{d.role}</td>
                    <td>{d.subcategory}</td>
                    <td>{d.location}</td>
                    <td>{d.factor}</td>
                    <td>{d.skill1}</td>
                    <td>{d.skill2}</td>
                    <td>{d.skill3}</td>
                    <td>{d.skill4}</td>
                    <td>{d.skill5}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}
