import { combineReducers } from "redux";
import authReducer from "./authReducer";
import salaryDashBoardReducer from "./salaryDashBoardReducer";
import commonReducer from "./commonReducer";
import otherReducer from "./otherReducer";
import userActivityReducer from "./userActivityReducer";
import paymentReducer from "./paymentReducer";
import subscriptionReducer from "./subscriptionReducer";
import blogReducer from "./blogReducer";
import adminActivityReducer from "./adminActivityReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  salaryDashboard: salaryDashBoardReducer,
  common: commonReducer,
  other: otherReducer,
  userActivity: userActivityReducer,
  adminActivity: adminActivityReducer,
  payment: paymentReducer,
  subscription: subscriptionReducer,
  blog: blogReducer,
});

export default rootReducer;
