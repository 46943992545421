import { SET_BLOG_REDUCER } from "../types/blogTypes";

export const setBlogReducer = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_BLOG_REDUCER,
      payload: data,
    });
  };
};
