import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { api_request } from "../../store/action/commonActions";
import {
  login_path,
  method_get,
  method_post,
  method_put,
  user_activity_log_path,
} from "../../config/config";
import {
  GET_USER_LIST_ERROR,
  GET_USER_LIST_LOADING,
  GET_USER_LIST_SUCCESS,
} from "../../store/types/userActivityType";
import Navbar from "../Layouts/Navbar";
import Footer from "../Layouts/Footer";
import { Navigate, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import CustomButton2 from "../Customs/CustomButton2";
import {
  VERIFY_USER_LOADING,
  VERIFY_USER_SUCCESS,
  VERIFY_USER_ERROR,
  ACTIVATE_USER_LOADING,
  ACTIVATE_USER_SUCCESS,
  ACTIVATE_USER_ERROR,
  DEACTIVATE_USER_LOADING,
  DEACTIVATE_USER_SUCCESS,
  DEACTIVATE_USER_ERROR,
  RENEW_USER_LOADING,
  RENEW_USER_SUCCESS,
  RENEW_USER_ERROR,
} from "../../store/types/adminActivityType";
import { convertMonthsToMilliseconds } from "../../utils/common";

export default function UserList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.auth?.user);
  const userList = useSelector((state) => state.userActivity?.userList);

  const verifyUserLoading = useSelector(
    (state) => state.adminActivity?.verifyUserLoading
  );
  const activateUserLoading = useSelector(
    (state) => state.adminActivity?.activateUserLoading
  );
  const deactivateUserLoading = useSelector(
    (state) => state.adminActivity?.deactivateUserLoading
  );
  const renewUserLoading = useSelector(
    (state) => state.adminActivity?.renewUserLoading
  );

  const [selectedUser, setSelectedUser] = useState(null);

  const fetchUserList = () => {
    dispatch(
      api_request(
        method_get,
        "/userList",
        null,
        {},
        GET_USER_LIST_LOADING,
        GET_USER_LIST_SUCCESS,
        GET_USER_LIST_ERROR,
        user?.accessToken
      )
    );
  };

  useEffect(() => {
    console.log("triggered");
    fetchUserList();
  }, [
    dispatch,
    user?.accessToken,
    verifyUserLoading,
    activateUserLoading,
    deactivateUserLoading,
    renewUserLoading,
  ]);

  const onViewLog = (d) => {
    navigate(user_activity_log_path, { state: { id: d.id } });
  };

  const handleVerify = (d) => {
    Swal.fire({
      title: "Confirm Verification",
      text: `Are you sure you want to verify ${d.name}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, verify!",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          api_request(
            method_put,
            "/adminVerifyUser",
            { id: d.id },
            null,
            VERIFY_USER_LOADING,
            VERIFY_USER_SUCCESS,
            VERIFY_USER_ERROR,
            user?.accessToken
          )
        );
      }
    });
  };

  const handleActivate = async (d) => {
    if (!d.isVerified) {
      Swal.fire({
        title: "User Not Verified",
        text: "Please verify the user before activation.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    setSelectedUser(d);
    Swal.fire({
      title: "Set Subscription Duration",
      text: `Set the subscription duration for ${d.name}.`,
      input: "number",
      inputLabel: "Subscription Duration (in months)",
      inputValidator: (value) => {
        if (!value || value < 1 || value > 36) {
          return "Please enter a valid number of months (1-36)";
        }
        return null;
      },
      inputAttributes: {
        min: 1,
        max: 36,
        step: 1,
      },
      customClass: {
        input: "custom-input-swal",
      },
      showCancelButton: true,
      confirmButtonText: "Save Changes",
      preConfirm: (value) => {
        if (value < 1 || value > 36) {
          Swal.showValidationMessage("Please enter a valid number of months");
          return false;
        }

        dispatch(
          api_request(
            method_put,
            "/activateUser",
            { id: d.id, duration: value },
            null,
            ACTIVATE_USER_LOADING,
            ACTIVATE_USER_SUCCESS,
            ACTIVATE_USER_ERROR,
            user?.accessToken
          )
        );
      },
    });
  };

  const handleDeactivate = (d) => {
    Swal.fire({
      title: "Confirm Deactivation",
      text: `Are you sure you want to deactivate ${d.name}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, deactivate!",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          api_request(
            method_put,
            "/deactivateUser",
            { id: d.id },
            null,
            DEACTIVATE_USER_LOADING,
            DEACTIVATE_USER_SUCCESS,
            DEACTIVATE_USER_ERROR,
            user?.accessToken
          )
        );
      }
    });
  };

  const handleRenew = (d) => {
    setSelectedUser(d);
    Swal.fire({
      title: "Renew Subscription",
      text: `Set the new subscription duration for ${d.name}.`,
      input: "number",
      inputLabel: "Subscription Duration (in months)",
      inputValidator: (value) => {
        if (!value || value < 1 || value > 36) {
          return "Please enter a valid number of months (1-36)";
        }
        return null;
      },
      inputAttributes: {
        min: 1,
        max: 36,
        step: 1,
      },
      customClass: {
        input: "custom-input-swal",
      },
      showCancelButton: true,
      confirmButtonText: "Renew Subscription",
      preConfirm: (value) => {
        if (value < 1 || value > 36) {
          Swal.showValidationMessage("Please enter a valid number of months");
          return false;
        }

        dispatch(
          api_request(
            method_put,
            "/renewUser",
            { id: d.id, duration: value },
            null,
            RENEW_USER_LOADING,
            RENEW_USER_SUCCESS,
            RENEW_USER_ERROR,
            user?.accessToken
          )
        );
      },
    });
  };

  if (!user) {
    return <Navigate to={login_path} />;
  }

  return (
    <>
      <Navbar />
      <div id="userList">
        <div id="userList-card" className="z-depth-1">
          {userList && (
            <table className="table responsive-table centered">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>USER NAME</th>
                  <th>NAME</th>
                  <th>EMAIL</th>
                  <th>VERIFICATION</th>
                  <th>ACTIVATION</th>
                  <th>EXPIRY</th>
                  <th>VIEW LOG</th>
                </tr>
              </thead>
              <tbody>
                {userList.map((d, i) => (
                  <tr key={i}>
                    <td>{d.id}</td>
                    <td>{d.userName}</td>
                    <td>{d.name}</td>
                    <td>{d.email}</td>
                    <td>
                      {d.isVerified ? (
                        "Verified"
                      ) : (
                        <CustomButton2
                          variant="warning"
                          onClick={() => handleVerify(d)}
                        >
                          Verify
                        </CustomButton2>
                      )}
                    </td>
                    <td>
                      {(() => {
                        const activeAt = new Date(d.activeAt);
                        const currentDate = new Date();
                        const timeDifference = currentDate - activeAt;
                        const subscription = convertMonthsToMilliseconds(
                          d.subscriptionInMonths
                        );

                        const isExpired = timeDifference > subscription;
                        if (d.isActive && isExpired) {
                          return (
                            <CustomButton2
                              variant="info"
                              id="renew-button"
                              onClick={() => handleRenew(d)}
                            >
                              Renew
                            </CustomButton2>
                          );
                        } else if (d.isActive) {
                          return (
                            <CustomButton2
                              variant="danger"
                              id="deactivate-button"
                              onClick={() => handleDeactivate(d)}
                            >
                              Deactivate
                            </CustomButton2>
                          );
                        } else {
                          return (
                            <CustomButton2
                              variant="success"
                              onClick={() => handleActivate(d)}
                            >
                              Activate
                            </CustomButton2>
                          );
                        }
                      })()}
                    </td>
                    <td>
                      {d.activeAt && d.isActive && d.isVerified
                        ? (() => {
                            const activeAt = new Date(d.activeAt);
                            const currentDate = new Date();
                            const timeDifference = currentDate - activeAt;

                            const subscription = convertMonthsToMilliseconds(
                              d.subscriptionInMonths
                            );

                            return timeDifference > subscription
                              ? "Expired"
                              : timeDifference < convertMonthsToMilliseconds(1)
                              ? `in ${Math.floor(
                                  (subscription - timeDifference) /
                                    (30 * 24 * 60 * 60 * 1000)
                                )} month(s) and ${Math.floor(
                                  ((subscription - timeDifference) %
                                    (30 * 24 * 60 * 60 * 1000)) /
                                    (24 * 60 * 60 * 1000)
                                )} days`
                              : `in ${Math.floor(
                                  (subscription - timeDifference) /
                                    (24 * 60 * 60 * 1000)
                                )} day(s) `;
                          })()
                        : "N/A"}
                    </td>
                    <td>
                      <i
                        className="material-icons onHover"
                        onClick={() => onViewLog(d)}
                      >
                        visibility
                      </i>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}
