import React from "react";

export default function CustomSelect(props) {
  const { id, data, ...restProps } = props;
  return (
    <>
      {restProps?.label && <label htmlFor={id}>{restProps.label}</label>}
      <select
        id={id}
        className="browser-default"
        {...restProps}
        style={{ backgroundColor: "#ebebeb" }}
      >
        {data?.map((d, i) => (
          <option key={i} value={d.value} disabled={d.disabled}>
            {d.label}
          </option>
        ))}
      </select>
    </>
  );
}
