import React, { useEffect, useState } from "react";
import Navbar from "../Layouts/Navbar";
import Footer from "../Layouts/Footer";
import PaymentCard from "./PaymentCard";
import StripeCheckout from "react-stripe-checkout";
import CustomButton1 from "../Customs/CustomButton1";
import { api_request } from "../../store/action/commonActions";
import {
  currency,
  home_path,
  login_path,
  method_post,
  one_year_subscription_amount,
  six_months_subscription_amount,
  stripe_token,
} from "../../config/config";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { setPaymentReducer } from "../../store/action/paymentAction";
import {
  CONFIRM_PAYMENT_ERROR,
  CONFIRM_PAYMENT_LOADING,
  CONFIRM_PAYMENT_SUCCESS,
} from "../../store/types/paymentTypes";
import {
  LOGOUT_ERROR,
  LOGOUT_LOADING,
  LOGOUT_SUCCESS,
} from "../../store/types/authTypes";

export default function Subscribe() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [AMOUNT, setAmount] = useState(null);
  const [DURATION, setDuration] = useState(null);
  const [USER_NAME, setUsername] = useState(null);

  const user = useSelector((state) => state.auth?.user);
  const confirmPaymentSuccess = useSelector(
    (state) => state?.payment.confirmPaymentSuccess
  );
  const confirmPaymentError = useSelector(
    (state) => state?.payment.confirmPaymentError
  );
  const confirmPaymentData = useSelector(
    (state) => state?.payment.confirmPaymentData
  );
  const confirmPaymentMessage = useSelector(
    (state) => state?.payment.confirmPaymentMessage
  );

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const amount = queryParams.get("amount");
    const userName = queryParams.get("userName");
    setAmount(Number(amount));
    setDuration(Number(amount) === 500 ? "Six Months" : "One Year");
    setUsername(userName);
  }, []);

  useEffect(() => {
    if (confirmPaymentSuccess) {
      let msg = "Payment Done\nid:" + confirmPaymentData?.id;
      swal("Success", msg, "success").then((d) => {
        navigate(home_path);
        dispatch(setPaymentReducer({ confirmPaymentSuccess: false }));
      });
    }
    if (confirmPaymentError) {
      swal("Oops!", confirmPaymentMessage, "error");
      dispatch(setPaymentReducer({ confirmPaymentError: false }));
    }
  }, [confirmPaymentSuccess, confirmPaymentError]);

  const handleToken = (token) => {
    dispatch(
      api_request(
        method_post,
        "/charge",
        { ...token, amount: AMOUNT * 100, userName: USER_NAME },
        null,
        CONFIRM_PAYMENT_LOADING,
        CONFIRM_PAYMENT_SUCCESS,
        CONFIRM_PAYMENT_ERROR
      )
    );
  };

  return (
    <>
      <Navbar />
      <div id="payment-gateway">
        <div id="payment-gateway-card" className="z-depth-1">
          <label>Amount : </label>
          <strong>{AMOUNT} &#x24;</strong>
          <br />
          <label>Duration : </label>
          <strong>{DURATION}</strong>
          <br /> <br />
          <StripeCheckout
            stripeKey={stripe_token}
            token={handleToken}
            amount={AMOUNT * 100} // Amount in cents
            currency={currency}
            name="People AI"
            description="Subscribe"
            panelLabel="Pay Now"
          />
          <br /> <br />
        </div>
      </div>
      <Footer />
    </>
  );
}
