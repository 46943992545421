import React, { useRef } from "react";
import Filters from "./Filters";
import Graphs from "./Graphs";
import CustomButton2 from "../../Customs/CustomButton2";
import Navbar from "../../Layouts/Navbar";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { login_path } from "../../../config/config";
import Footer from "../../Layouts/Footer";
// import NonSubscriberDashBoard from "../../Layouts/NonSubscriberDashBoard";
import ComponentLoader from "../../Layouts/ComponentLoader";
import "./../../../styles/home.css";
const findExperience = (exp) => {
  if (exp === "0.9") {
    return "0-3 Years";
  } else if (exp === "1") {
    return "4-6 years";
  } else if (exp === "1.1") {
    return "7+ years";
  }
};
export default function SalaryDashboard() {
  const user = useSelector((state) => state.auth?.user);
  const chart1loading = useSelector(
    (state) => state.salaryDashboard?.chart1loading
  );
  const chart2loading = useSelector(
    (state) => state.salaryDashboard?.chart2loading
  );
  const chart3loading = useSelector(
    (state) => state.salaryDashboard?.chart3loading
  );
  const chart4loading = useSelector(
    (state) => state.salaryDashboard?.chart4loading
  );
  const chart5loading = useSelector(
    (state) => state.salaryDashboard?.chart5loading
  );
  const chart6loading = useSelector(
    (state) => state.salaryDashboard?.chart6loading
  );

  const salaryDashboard = useSelector((state) => state.common.salaryDashboard);

  const printAreaRef = useRef(null);

  const handlePrint = () => {
    const originalContents = document.body.innerHTML;

    const printContent = printAreaRef.current;
    const printContents = printContent.innerHTML;

    document.body.innerHTML = printContents;

    alert(
      `To ensure background colors are printed, please enable the 'Background graphics' option in your browser settings before printing`
    );
    window.print();
    // Restore the original content after printing
    document.body.innerHTML = originalContents;
  };

  if (!user) {
    return <Navigate to={login_path} />;
  }
  // if (!user?.subscribed) {
  //   return <NonSubscriberDashBoard />;
  // }

  return (
    <>
      {(chart1loading ||
        chart2loading ||
        chart3loading ||
        chart4loading ||
        chart5loading ||
        chart6loading) && <ComponentLoader text="Loading..." />}
      <Navbar />
      <div className="dashboard" id="salary-dashboard">
        <div className="container">
          {/* <span className="custom-message">
            This is a free version till end of June. In the paid version, we
            will have trend analysis (pay over time), all roles (apart from IT),
            creating custom peer group for you, alerts for your competition.
          </span> */}
        </div>
        <div id="salary-dashboard">
          <div className="">
            <Filters />
          </div>
          <div className="" id="printArea" ref={printAreaRef}>
            <div
              className="extra-details-print "
              style={{ textAlign: "center" }}
            >
              {" "}
              <h4>
                {salaryDashboard?.searchRole} |{" "}
                {findExperience(salaryDashboard?.experience)}
              </h4>
            </div>
            <Graphs />
          </div>
        </div>

        {user?.role !== "demo" && (
          <div style={{ display: "grid", placeItems: "center" }}>
            {" "}
            <CustomButton2
              style={{ marginBottom: "20px" }}
              onClick={handlePrint}
            >
              Download PDF
            </CustomButton2>
          </div>
        )}
      </div>

      <Footer />
    </>
  );
}
