const yup = require("yup");

export const loginSchema = yup.object({
  userId: yup.string().required("User name or email is required"),
  password: yup.string().required("Password is required"),
});

export const registrationSchema = yup.object({
  name: yup.string(),
  userName: yup.string().required("username  is required"),
  email: yup
    .string()
    .email()
    .test(
      "email-domain",
      "Gmail, Yahoo, Outlook etc. address will not work - only company email.",
      (value) => {
        if (!value) return true; // Skip validation if value is empty

        const allowedDomains = [
          "gmail.com",
          "yahoo.com",
          "outlook.com",
          "rediffmail.com",
        ];
        const domain = value.split("@")[1]; // Extract domain from email

        return !allowedDomains.includes(domain);
      }
    )
    .required("Email is required"),
  password: yup.string().required("Password is required"),
  confirmPassword: yup
    .string()
    .required("Name or email is required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  title: yup.string(),
  company: yup.string().required("Company or email is required"),
  phone: yup.string(),
});

export const resetPasswordSchema = yup.object({
  userId: yup.string().required("Email or user name is required"),
});

export const changePasswordSchema = yup.object({
  password: yup.string().required("Password is required"),
  confirmPassword: yup
    .string()
    .required("Name or email is required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  otp: yup.string().required("Otp is required"),
});
