import React from "react";
import Footer from "../Layouts/Footer";
import Navbar from "../Layouts/Navbar";

export default function PrivacyPolicy() {
  return (
    <>
      <Navbar />
      <div className="notice">
        <div className="row">
          <p>
            This Privacy Policy explains how we collect, use, and protect the
            information you provide when you sign up for our website. Your
            privacy is important to us, and we are committed to protecting it.
          </p>
        </div>
        <div className="row">
          <h5>Information We Collect</h5>
          <p>
            When you sign up for our website, we collect certain information
            that you provide to us, such as your name, email address, and
            password. We may also collect information about your device, such as
            your IP address and browser type.
          </p>
        </div>
        <div className="row">
          <h5>How We Use Your Information</h5>
          <p>
            We use the information we collect to provide you with access to our
            website and to communicate with you. We may also use your
            information to improve our website and to personalize your
            experience. We may use cookies and other tracking technologies to
            collect information about your activity on our website. This
            information may be used to deliver targeted advertising to you or to
            analyze trends and usage patterns.
          </p>
        </div>
        <div className="row">
          <h5>Sharing Your Information</h5>
          <p>
            We will not sell or share your information with third parties,
            except as necessary to provide you with our services or as required
            by law. We may share your information with service providers who
            help us operate our website, such as hosting providers or email
            service providers.
          </p>
          <p>
            We may also share your information if we are acquired by another
            company or if we merge with another company
          </p>
        </div>
        <div className="row">
          <h5>Protecting Your Information</h5>
          <p>
            We take reasonable measures to protect your information from
            unauthorized access, disclosure, or destruction. We use
            industry-standard security technologies and procedures to protect
            your information.
          </p>
          <p>
            However, no method of transmission over the internet or method of
            electronic storage is 100% secure. Therefore, we cannot guarantee
            the absolute security of your information.
          </p>
        </div>
        <div className="row">
          <h5>Your Choices</h5>
          <p>
            You can choose not to provide us with certain information, but this
            may limit your ability to use our website.
          </p>
          <p>
            You can also choose to opt out of receiving promotional emails from
            us by following the instructions in those emails. Please note that
            even if you opt out of receiving promotional emails, we may still
            send you non-promotional emails, such as those related to your
            account or transactions.
          </p>
        </div>
        <div className="row">
          <h5>Updating Your Information</h5>
          <p>
            You can update your information by logging into your account on our
            website and editing your profile. You can also contact us if you
            need assistance updating your information.
          </p>
        </div>
        <div className="row">
          <h5>Changes to This Policy</h5>
          <p>
            We may update this Privacy Policy from time to time. If we make
            significant changes to this Policy, we will notify you by posting a
            notice on our website or by sending you an email.
          </p>
        </div>
        <div className="row">
          <h5>Contact Us</h5>
          <p>
            If you have any questions or concerns about this Privacy Policy,
            please contact us at info@peopleai.com.au.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
}
