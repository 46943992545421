import React, { useEffect, useState } from "react";
import Navbar from "../Layouts/Navbar";
import Footer from "../Layouts/Footer";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { login_path, method_get, method_post } from "../../config/config";
import CustomButton1 from "../Customs/CustomButton1";
import { api_request } from "../../store/action/commonActions";
import swal from "sweetalert";
import { setBlogReducer } from "../../store/action/blogAction";
import Loader from "../Layouts/Loader";
import {
  BLOG_POST_ERROR,
  BLOG_POST_LOADING,
  BLOG_POST_SUCCESS,
  GET_COMMENTS_LOAADING,
  GET_COMMENTS_SUCCCESS,
  GET_COMMENT_ERROR,
} from "../../store/types/blogTypes";
import CommentCard from "./CommentCard";

export default function Comments() {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth?.user);
  const blogpostLoading = useSelector((state) => state.blog.blogpostLoading);
  const blogpostSuccess = useSelector((state) => state.blog.blogpostSuccess);
  const blogpostErr = useSelector((state) => state.blog.blogpostErr);
  const blogpostMsg = useSelector((state) => state.blog.blogpostMsg);
  const comments = useSelector((state) => state.blog.comments);

  const [comment, setComment] = useState(null);

  const onChange = (e) => {
    setComment(e.target.value);
  };

  const onPost = (e) => {
    e.preventDefault();
    dispatch(
      api_request(
        method_post,
        "/addPost",
        { comment: comment },
        null,
        BLOG_POST_LOADING,
        BLOG_POST_SUCCESS,
        BLOG_POST_ERROR,
        user?.accessToken
      )
    );
  };

  useEffect(() => {
    dispatch(
      api_request(
        method_get,
        "/getComments",
        null,
        null,
        GET_COMMENTS_LOAADING,
        GET_COMMENTS_SUCCCESS,
        GET_COMMENT_ERROR,
        user?.accessToken
      )
    );
  }, []);

  useEffect(() => {
    if (blogpostSuccess) {
      swal("Success", blogpostMsg, "success");
      dispatch(setBlogReducer({ blogpostSuccess: false }));
    }
    if (blogpostErr) {
      alert(blogpostMsg);
      dispatch(setBlogReducer({ blogpostErr: false }));
    }
  }, [blogpostSuccess, blogpostErr]);

  if (!user) {
    return <Navigate to={login_path} />;
  }
  if (blogpostLoading) {
    return <Loader />;
  }
  return (
    <>
      <Navbar />
      <div id="comment">
        <div id="comment-card">
          {comments?.map((d, i) => (
            <CommentCard data={d} />
          ))}
          <textarea
            id="comment"
            placeholder="Leave your comment..."
            onChange={onChange}
            value={comment}
          />
          <CustomButton1 onClick={onPost}>Submit</CustomButton1>
        </div>
      </div>
      <Footer />
    </>
  );
}
