import React, { useEffect } from "react";
import Navbar from "../Layouts/Navbar";
import { Formik } from "formik";
import { resetPasswordSchema } from "../../utils/formValidationSchemas";
import CustomLabel from "../Customs/CustomLabel";
import CustomInput2 from "../Customs/CustomInput2";
import CustomButton2 from "../Customs/CustomButton2";
import { useDispatch, useSelector } from "react-redux";
import { api_request } from "../../store/action/commonActions";
import { method_post } from "../../config/config";
import {
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_LOADING,
  RESET_PASSWORD_SUCCESS,
} from "../../store/types/authTypes";
import Loader from "../Layouts/Loader";
import swal from "sweetalert";
import { setAuthreducer } from "../../store/action/authAction";
import Footer from "../Layouts/Footer";

export default function ResetPassword() {
  const dispatch = useDispatch();

  const resetPasswordLoading = useSelector(
    (state) => state.auth?.resetPasswordLoading
  );
  const resetPasswordSuccess = useSelector(
    (state) => state.auth?.resetPasswordSuccess
  );
  const resetPasswordError = useSelector(
    (state) => state.auth?.resetPasswordError
  );
  const resetPasswordMessage = useSelector(
    (state) => state.auth?.resetPasswordMessage
  );

  useEffect(() => {
    if (resetPasswordSuccess) {
      swal("Success", resetPasswordMessage, "success");
      dispatch(setAuthreducer({ resetPasswordSuccess: false }));
    }
    if (resetPasswordError) {
      swal("Oops!", resetPasswordMessage, "error");
      dispatch(setAuthreducer({ resetPasswordError: false }));
    }
  }, [resetPasswordSuccess, resetPasswordError]);

  const submit = (values) => {
    dispatch(
      api_request(
        method_post,
        "/resetPassword",
        { userName: values?.userId },
        null,
        RESET_PASSWORD_LOADING,
        RESET_PASSWORD_SUCCESS,
        RESET_PASSWORD_ERROR
      )
    );
  };

  if (resetPasswordLoading) {
    return <Loader />;
  }

  return (
    <>
      <Navbar />
      <div className="row">
        <div className="col l6 s12 auth-left">
          <Formik
            initialValues={{
              userId: "",
            }}
            validationSchema={resetPasswordSchema}
            onSubmit={(values, actions) => {
              submit(values);
            }}
          >
            {(formikProps) => (
              <div className="center" id="lostpassword">
                <div className="row">
                  <span style={{ color: "black", fontSize: 16 }}>
                    Reset Password
                  </span>
                  <br />
                  <span style={{ color: "red" }}>
                    Lost your password? Please enter your username or email
                    address. You will receive a link to create a new password
                    via email.
                  </span>
                  <CustomLabel required={true}>Username or Email</CustomLabel>
                  <CustomInput2
                    id="userId"
                    onBlur={formikProps.handleBlur("userId")}
                    onChange={formikProps.handleChange("userId")}
                    value={formikProps.values.userId}
                    formikProps={formikProps}
                  />
                </div>
                <div className="row">
                  <CustomButton2
                    type="button"
                    onClick={formikProps.handleSubmit}
                  >
                    Reset Password
                  </CustomButton2>
                </div>
              </div>
            )}
          </Formik>
        </div>
        <div className="col l6 auth-right hide-on-med-and-down"></div>
      </div>
      <Footer />
    </>
  );
}
