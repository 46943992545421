import React from "react";

export default function CustomButton2(props) {
  return (
    <button
      className="btn custom-button2"
      type={props.type || "button"}
      {...props}
    >
      {props.children}
    </button>
  );
}
