import { Formik } from "formik";
import React, { useEffect } from "react";
import Navbar from "../Layouts/Navbar";
import Loader from "../Layouts/Loader";
import CustomInput2 from "../Customs/CustomInput2";
import CustomLabel from "../Customs/CustomLabel";
import CustomButton1 from "../Customs/CustomButton1";
import CustomLink from "../Customs/CustomLink";
import { login_path, method_post } from "../../config/config";
import { useDispatch, useSelector } from "react-redux";
import { api_request } from "../../store/action/commonActions";
import {
  REGISTRATION_ERROR,
  REGISTRATION_LOADING,
  REGISTRATION_SUCCESS,
} from "../../store/types/authTypes";
import swal from "sweetalert";
import { setAuthreducer } from "../../store/action/authAction";

import Footer from "../Layouts/Footer";

export default function Register() {
  const dispatch = useDispatch();

  const registrationLoading = useSelector(
    (state) => state.auth?.registrationLoading
  );
  const registrationSuccess = useSelector(
    (state) => state.auth?.registrationSuccess
  );
  const registrationError = useSelector(
    (state) => state.auth?.registrationError
  );
  const registrationMessage = useSelector(
    (state) => state.auth?.registrationMessage
  );

  useEffect(() => {
    if (registrationSuccess) {
      swal("Success", registrationMessage, "success");
      dispatch(setAuthreducer({ registrationSuccess: false }));
    }
    if (registrationError) {
      swal("Oops!", registrationMessage, "error");
      dispatch(setAuthreducer({ registrationError: false }));
    }
  }, [registrationSuccess, registrationError]);

  const register = (value) => {
    dispatch(
      api_request(
        method_post,
        "/register",
        value,
        null,
        REGISTRATION_LOADING,
        REGISTRATION_SUCCESS,
        REGISTRATION_ERROR
      )
    );
  };

  if (registrationLoading) {
    return <Loader />;
  }

  return (
    <>
      <Navbar />
      <div className="row">
        <div id="auth-left-register" className="col l6 s12 auth-left">
          <Formik
            initialValues={{
              name: "",
              userName: "",
              email: "",
              password: "",
              confirmPassword: "",
              title: "",
              company: "",
              phone: "",
            }}
            // validationSchema={registrationSchema}
            onSubmit={(values, actions) => {
              delete values.confirmPassword;
              register(values);
            }}
          >
            {(formikProps) => (
              <div className="center" id="register">
                <div className="row">
                  <CustomLink to={login_path}>
                    Login here if already registered
                  </CustomLink>
                  <br /> <br />
                  <h5
                    style={{
                      color: "black",
                      marginTop: 0,
                    }}
                  >
                    Register
                  </h5>
                  <div className="row" style={{ marginTop: 15 }}>
                    <div className="col s12 l6">
                      <CustomLabel>Name</CustomLabel>
                      <CustomInput2
                        id="name"
                        onBlur={formikProps.handleBlur("name")}
                        onChange={formikProps.handleChange("name")}
                        value={formikProps.values.name}
                        formikProps={formikProps}
                      />
                    </div>
                    <div className="col s12 l6">
                      <CustomLabel required={true}>User Name</CustomLabel>
                      <CustomInput2
                        id="userName"
                        onBlur={formikProps.handleBlur("userName")}
                        onChange={formikProps.handleChange("userName")}
                        value={formikProps.values.userName}
                        formikProps={formikProps}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col s12 l6">
                      <CustomLabel required={true}>User Email</CustomLabel>
                      <CustomInput2
                        id="email"
                        onBlur={formikProps.handleBlur("email")}
                        onChange={formikProps.handleChange("email")}
                        value={formikProps.values.email}
                        formikProps={formikProps}
                      />
                    </div>
                    <div className="col s12 l6">
                      <CustomLabel>Title</CustomLabel>
                      <CustomInput2
                        id="title"
                        onBlur={formikProps.handleBlur("title")}
                        onChange={formikProps.handleChange("title")}
                        value={formikProps.values.title}
                        formikProps={formikProps}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col s12 l6">
                      <CustomLabel required={true}>Company</CustomLabel>
                      <CustomInput2
                        id="company"
                        onBlur={formikProps.handleBlur("company")}
                        onChange={formikProps.handleChange("company")}
                        value={formikProps.values.company}
                        formikProps={formikProps}
                      />
                    </div>
                    <div className="col s12 l6">
                      <CustomLabel>Phone</CustomLabel>
                      <CustomInput2
                        id="phone"
                        onBlur={formikProps.handleBlur("phone")}
                        onChange={formikProps.handleChange("phone")}
                        value={formikProps.values.phone}
                        formikProps={formikProps}
                      />
                    </div>
                  </div>
                  {/* <CustomLabel required={true}>Password</CustomLabel>
                  <CustomPassword
                    id="password"
                    onBlur={formikProps.handleBlur("password")}
                    onChange={formikProps.handleChange("password")}
                    value={formikProps.values.password}
                    formikProps={formikProps}
                  />
                  <CustomLabel required={true}>Confirm Password</CustomLabel>
                  <CustomPassword
                    id="confirmPassword"
                    onBlur={formikProps.handleBlur("confirmPassword")}
                    onChange={formikProps.handleChange("confirmPassword")}
                    value={formikProps.values.confirmPassword}
                    formikProps={formikProps}
                  /> */}
                </div>
                <div className="row" style={{ marginBottom: "10px" }}>
                  <CustomButton1
                    type="button"
                    onClick={formikProps.handleSubmit}
                  >
                    Register
                  </CustomButton1>
                </div>
              </div>
            )}
          </Formik>
        </div>
        <div
          id="auth-right-register"
          className="col l6 auth-right hide-on-med-and-down"
        ></div>
      </div>
      <Footer />
    </>
  );
}
