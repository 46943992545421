import {
  CONFIRM_PAYMENT_ERROR,
  CONFIRM_PAYMENT_LOADING,
  CONFIRM_PAYMENT_SUCCESS,
  SET_PAYMENT_REDUCER,
} from "../types/paymentTypes";

const initState = {
  confirmPaymentLoading: false,
  confirmPaymentSuccess: false,
  confirmPaymentError: false,
  confirmPaymentData: null,
  confirmPaymentMessage: null,
};

const paymentReducer = (state = initState, action) => {
  //   console.log(action);
  switch (action.type) {
    case CONFIRM_PAYMENT_LOADING:
      return {
        ...state,
        confirmPaymentLoading: true,
        confirmPaymentSuccess: false,
        confirmPaymentError: false,
        confirmPaymentData: null,
        confirmPaymentMessage: null,
      };
    case CONFIRM_PAYMENT_SUCCESS:
      return {
        ...state,
        confirmPaymentLoading: false,
        confirmPaymentSuccess: true,
        confirmPaymentError: false,
        confirmPaymentData: action.payload,
        confirmPaymentMessage: action.message || "Payment Done Successfully",
      };
    case CONFIRM_PAYMENT_ERROR:
      return {
        ...state,
        confirmPaymentLoading: false,
        confirmPaymentSuccess: false,
        confirmPaymentError: true,
        confirmPaymentData: null,
        confirmPaymentMessage:
          action.message || "Error to payment please try again later.",
      };
    case SET_PAYMENT_REDUCER:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default paymentReducer;
