import {
  VERIFY_USER_SUCCESS,
  VERIFY_USER_LOADING,
  VERIFY_USER_ERROR,
  ACTIVATE_USER_SUCCESS,
  ACTIVATE_USER_LOADING,
  ACTIVATE_USER_ERROR,
  DEACTIVATE_USER_SUCCESS,
  DEACTIVATE_USER_LOADING,
  DEACTIVATE_USER_ERROR,
  RENEW_USER_SUCCESS,
  RENEW_USER_LOADING,
  RENEW_USER_ERROR,
  SET_ADMIN_ACTIVITY_REDUCER,
} from "../types/adminActivityType";

const initState = {
  verifyUserLoading: false,
  verifyUserSuccess: false,
  verifyUserError: null,

  activateUserLoading: false,
  activateUserSuccess: false,
  activateUserError: null,

  deactivateUserLoading: false,
  deactivateUserSuccess: false,
  deactivateUserError: null,

  renewUserLoading: false,
  renewUserSuccess: false,
  renewUserError: null,
};

const adminActivityReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_ADMIN_ACTIVITY_REDUCER:
      return { ...state, ...action.payload };

    case VERIFY_USER_LOADING:
      return { ...state, verifyUserLoading: true, verifyUserError: null };
    case VERIFY_USER_SUCCESS:
      return { ...state, verifyUserLoading: false, verifyUserSuccess: true };
    case VERIFY_USER_ERROR:
      return {
        ...state,
        verifyUserLoading: false,
        verifyUserSuccess: false,
        verifyUserError: action.payload,
      };

    case ACTIVATE_USER_LOADING:
      return { ...state, activateUserLoading: true, activateUserError: null };
    case ACTIVATE_USER_SUCCESS:
      return {
        ...state,
        activateUserLoading: false,
        activateUserSuccess: true,
      };
    case ACTIVATE_USER_ERROR:
      return {
        ...state,
        activateUserLoading: false,
        activateUserSuccess: false,
        activateUserError: action.payload,
      };

    case DEACTIVATE_USER_LOADING:
      return {
        ...state,
        deactivateUserLoading: true,
        deactivateUserError: null,
      };
    case DEACTIVATE_USER_SUCCESS:
      return {
        ...state,
        deactivateUserLoading: false,
        deactivateUserSuccess: true,
      };
    case DEACTIVATE_USER_ERROR:
      return {
        ...state,
        deactivateUserLoading: false,
        deactivateUserSuccess: false,
        deactivateUserError: action.payload,
      };

    case RENEW_USER_LOADING:
      return { ...state, renewUserLoading: true, renewUserError: null };
    case RENEW_USER_SUCCESS:
      return { ...state, renewUserLoading: false, renewUserSuccess: true };
    case RENEW_USER_ERROR:
      return {
        ...state,
        renewUserLoading: false,
        renewUserSuccess: false,
        renewUserError: action.payload,
      };

    default:
      return state;
  }
};

export default adminActivityReducer;
