// export const backendUrl = "http://localhost:8080";
export const backendUrl = " https://backend.peopleai.online";

export const refreshInterval = 1 * 60 * 1000;

export const stripe_token =
  "pk_test_51NKeYlSJzZRTqvxdQc6TzjEMg6DdZxH3pOWspLWKwk2gy4WMtPExpyEttwFMUKIoYLy5yJ53DsxqTrqIgtI8Mc2n00lOAUdx7V";

export const isDemo = true;

export const method_get = "get";
export const method_post = "post";
export const method_put = "put";

export const home_path = "/";
export const home_path_2 = "/";
export const login_path = "/signIn";
export const registration_path = "/userRegister";
export const reset_password_path = "/reset_password";
export const salary_dashboard_path = "/paydata";
export const verify_user = "/verify_user";
export const change_password_path = "/change_password";
export const terms_condition_path = "/terms-and-conditions";
export const privacy_policy_path = "/privacy-policy";
export const user_list_path = "/user_list";
export const user_activity_log_path = "/user_activity_log";
export const test_path = "/test_path";
export const subscription_path = "/subscription_path";
export const last_sql_past = "/lastQuery";
export const contact_us_path = "/contact";
export const demo_path = "/demo";
export const blog_path = "/comments";

export const role_admin = "admin";

export const timezone = "Asis/Kolkata";

export const currency = "USD";

export const six_months_subscription_amount = "500";
export const one_year_subscription_amount = "900";

export const graph_blue_bar = "#2598e6";
export const graph_golden_bar = "#e07c2b";
export const graph_red_bar = "#d62d60";
