import React from "react";

export default function CustomInput3(props) {
  const { formikProps, id, ...restProps } = props;
  return (
    <>
      {props?.label && <label htmlFor={id}>{props.label}</label>}
      <input {...restProps} className="custom-input-3" id={id} />
      <span className="error-text">
        {formikProps?.touched[id] && formikProps?.errors[id]}
      </span>
    </>
  );
}
