import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { api_request } from "../../store/action/commonActions";
import { login_path, method_put } from "../../config/config";
import {
  USER_VERIFICATION_ERROR,
  USER_VERIFICATION_LOADING,
  USER_VERIFICATION_SUCCESS,
} from "../../store/types/authTypes";
import swal from "sweetalert";
import { setAuthreducer } from "../../store/action/authAction";
import Loader from "../Layouts/Loader";
import { Navigate } from "react-router-dom";

export default function VerifyUser() {
  const dispatch = useDispatch();

  const userVerificationLoading = useSelector(
    (state) => state.auth?.userVerificationLoading
  );
  const userVerificationSuccess = useSelector(
    (state) => state.auth?.userVerificationSuccess
  );
  const userVerificationError = useSelector(
    (state) => state.auth?.userVerificationError
  );
  const userVerificationMessage = useSelector(
    (state) => state.auth?.userVerificationMessage
  );

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get("id");
    const otp = queryParams.get("otp");
    dispatch(
      api_request(
        method_put,
        "/verifyUser",
        { id, otp },
        null,
        USER_VERIFICATION_LOADING,
        USER_VERIFICATION_SUCCESS,
        USER_VERIFICATION_ERROR
      )
    );
  }, []);

  useEffect(() => {
    if (userVerificationSuccess) {
      swal(
        "Success",
        userVerificationMessage ||
          "User verified! Now Login with your credentials",
        "success"
      );
      dispatch(setAuthreducer({ userVerificationSuccess: false }));
    }
    if (userVerificationError) {
      swal("Oops!", userVerificationMessage || "Error to verify", "error");
      dispatch(setAuthreducer({ userVerificationError: false }));
    }
  }, [userVerificationSuccess, userVerificationError]);

  if (userVerificationLoading) {
    return <Loader />;
  }
  if (userVerificationSuccess || userVerificationError) {
    return <Navigate to={login_path} />;
  }
}
