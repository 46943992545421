import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import LoaderComp from "../../Layouts/LoaderComp";
import { customYAxisFormatter } from "../../../utils/salaryDashboardDataHeler";
import { pastelColors } from "../../../config/constants"; // Import pastel colors
import { useDispatch, useSelector } from "react-redux";
import { setSalarydashboard } from "../../../store/action/salaryDashboardAction";

export default function MedianSalarySkill({ role }) {
  const dispatch = useDispatch();
  const isDemoUser = role === "demo";

  const chart3 = useSelector((state) => state.salaryDashboard?.chart3);
  const chart3loading = useSelector(
    (state) => state.salaryDashboard?.chart3loading
  );
  const chart3success = useSelector(
    (state) => state.salaryDashboard?.chart3success
  );
  const chart3error = useSelector(
    (state) => state.salaryDashboard?.chart3error
  );
  const common = useSelector((state) => state.common);

  const [data, setData] = useState(null);

  useEffect(() => {
    if (chart3) {
      let d = [];
      for (let i = 0; i < chart3.length; i++) {
        d.push({
          x: chart3[i]?.skill,
          y: chart3[i]?.median,
          fillColor: pastelColors[i % pastelColors.length],
        });
      }
      setData(d);
      dispatch(setSalarydashboard({ chart3success: false }));
    }
  }, [chart3success, chart3]);

  useEffect(() => {
    if (chart3loading) {
      setData(null);
    }
  }, [chart3loading]);

  // Determine colors based on whether it's a demo user or not
  const chartColors = isDemoUser
    ? ["#cccccc"]
    : pastelColors.slice(0, data?.length || pastelColors.length);

  const options = {
    dataLabels: {
      enabled: !isDemoUser,
      formatter: function (val) {
        return `${customYAxisFormatter(val)}`;
      },
      style: {
        colors: ["#000000"],
      },
    },
    chart: {
      toolbar: {
        show: false,
      },
      type: "bar",
      height: 400,
      events: {
        mouseOver: (event, chartContext, config) => {
          if (isDemoUser) {
            event.preventDefault();
          }
        },
      },
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: !isDemoUser,
        },
      },
    },
    yaxis: {
      labels: {
        show: !isDemoUser,
        formatter: customYAxisFormatter,
        style: {
          colors: "#000000",
        },
      },
      axisTicks: {
        show: true,
      },
    },
    xaxis: {
      labels: {
        show: !isDemoUser,
        style: {
          colors: "#000000",
        },
      },
    },
    tooltip: {
      enabled: !isDemoUser,
    },
    zoom: {
      enabled: !isDemoUser,
    },
    stroke: {
      width: isDemoUser ? 0 : 0, // Adjust stroke width for demo users
    },
    colors: chartColors, // Apply pastel colors here
    fill: {
      type: "solid",
      gradient: {
        shade: isDemoUser ? "#cccccc" : undefined,
        shadeIntensity: 0.1,
        opacityFrom: 0.8,
        opacityTo: 0.9,
        stops: [0, 95, 100],
      },
    },
  };

  return (
    <div className={`graph z-depth-3 ${isDemoUser ? "disabled-chart" : ""}`}>
      {data?.length >= 1 &&
      common.salaryDashboard?.skill &&
      common.salaryDashboard?.skill.trim() !== "" ? (
        <div className="graph-div">
          <span className="graph-heading">
            {`Median salary for "${common?.salaryDashboard?.searchRole}" jobs in ${common.salaryDashboard?.location} for selected skills`}
          </span>
          <ReactApexChart
            options={options}
            series={[{ name: "Job Salary", data: data }]}
            type="bar"
            height={300}
          />
        </div>
      ) : (
        <div className="graph-nodata-div">
          <h6 className="graph-heading">
            {"Median Salary in " +
              common.salaryDashboard?.location +
              " For selected skills"}
          </h6>
          {chart3loading ? (
            <LoaderComp />
          ) : common.salaryDashboard?.skill &&
            common.salaryDashboard?.skill.trim() !== "" ? (
            <span>No Data</span>
          ) : (
            <span>No skills have been given</span>
          )}
        </div>
      )}
    </div>
  );
}
