import React, { useState } from "react";

export default function CustomPassword(props) {
  const { formikProps, id, ...restProps } = props;

  const [visible, setVisible] = useState(false);

  return (
    <div>
      <div
        style={{
          position: "relative",
        }}
      >
        <input
          id={id}
          type={visible ? "text" : "password"}
          {...restProps}
          style={{
            backgroundColor: "#ffffff",
            margin: "0",
            paddingLeft: "3%",
            borderRadius: "0",
            border: "NONE",
          }}
        />
        <i
          className="material-icons toogle-password   onHover"
          onClick={() => {
            setVisible(visible ? false : true);
          }}
        >
          {visible ? "visibility_off" : "visibility"}
        </i>
      </div>

      <br />
      {formikProps?.touched[id] && formikProps?.errors[id] ? (
        <span className="error-text">
          {formikProps?.touched[id] && formikProps?.errors[id]}
        </span>
      ) : null}
    </div>
  );
}
