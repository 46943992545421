import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";
import LoaderComp from "../../Layouts/LoaderComp";
import { pastelColors } from "../../../config/constants";

export default function Top5CategoriesPieChart({ role }) {
  const isDemoUser = role === "demo";
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const chart8 = useSelector((state) => state.salaryDashboard?.chart8);
  const chart8loading = useSelector(
    (state) => state.salaryDashboard?.chart8loading
  );
  const chart8success = useSelector(
    (state) => state.salaryDashboard?.chart8success
  );
  const chart8error = useSelector(
    (state) => state.salaryDashboard?.chart8error
  );
  const common = useSelector((state) => state.common);

  const handleDataLabelVisibility = (val, opts) => {
    if (isDemoUser) return "";
    if (val < 5) {
      return "";
    } else {
      return opts.w.globals.labels[opts.seriesIndex];
    }
  };

  const [options, setOptions] = useState({
    chart: {
      type: "pie",
    },
    labels: [],
    legend: {
      position: "bottom",
      itemMargin: {
        horizontal: 5,
        vertical: 10,
      },
      show: !isDemoUser, // Hide legend if demo user
    },
    colors: pastelColors, // Apply pastel colors
    plotOptions: {
      pie: {
        dataLabels: {
          formatter: handleDataLabelVisibility,
          offset: -15,
          minAngleToShowLabel: 10,
          style: {
            fontSize: "16px",
            colors: isDemoUser ? "#cccccc" : undefined, // Gray out labels if demo user
          },
        },
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  });

  const updateOptions = () => {
    setOptions((prevOptions) => ({
      ...prevOptions,
      legend: {
        ...prevOptions.legend,
        show: !isDemoUser,
      },
      plotOptions: {
        ...prevOptions.plotOptions,
        pie: {
          dataLabels: {
            ...prevOptions.plotOptions.pie.dataLabels,
            style: {
              ...prevOptions.plotOptions.pie.dataLabels.style,
              colors: isDemoUser ? "#cccccc" : undefined,
            },
          },
        },
      },
    }));
  };

  useEffect(() => {
    updateOptions();
  }, [chart8, isDemoUser]);

  const [series, setSeries] = useState([]);

  useEffect(() => {
    if (chart8) {
      const categoryCounts = {};
      const filteredChart8 = chart8.filter((d) => d.category !== null);

      filteredChart8.forEach((job) => {
        const category = job.category;
        categoryCounts[category] = (categoryCounts[category] || 0) + 1;
      });

      const sortedCategories = Object.keys(categoryCounts).sort(
        (a, b) => categoryCounts[b] - categoryCounts[a]
      );

      const top5Categories = sortedCategories.slice(0, 5);

      setOptions((prevOptions) => ({
        ...prevOptions,
        labels: top5Categories,
      }));

      setSeries(top5Categories.map((category) => categoryCounts[category]));
    }
  }, [chart8success, chart8]);

  useEffect(() => {
    if (chart8loading) {
      setSeries([]);
    }
  }, [chart8loading]);

  return (
    <div className={`graph z-depth-3 ${isDemoUser ? "disabled-chart" : ""}`}>
      {series.length > 0 && !chart8error ? (
        <div className={isMobile ? "graph-div" : ""}>
          <span
            className="graph-heading"
            style={{ color: isDemoUser ? "#cccccc" : "black" }}
          >
            {`Distribution of jobs in top common categories in ${common.salaryDashboard?.location}`}
          </span>
          <Chart options={options} series={series} type="pie" width={420} />
        </div>
      ) : (
        <div className="graph-nodata-div">
          <h6
            className="graph-heading"
            style={{ color: isDemoUser ? "#cccccc" : "black" }}
          >
            {`Distribution of jobs in top common categories in ${common.salaryDashboard?.location}`}
          </h6>

          {chart8loading ? <LoaderComp /> : <span>No Data</span>}
        </div>
      )}
    </div>
  );
}
