import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  contact_us_path,
  home_path_2,
  last_sql_past,
  login_path,
  method_post,
  role_admin,
  salary_dashboard_path,
  user_list_path,
} from "../../config/config";
import { useDispatch, useSelector } from "react-redux";
import { api_request } from "../../store/action/commonActions";
import {
  LOGOUT_ERROR,
  LOGOUT_LOADING,
  LOGOUT_SUCCESS,
} from "../../store/types/authTypes";
import M from "materialize-css";

export default function Navbar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.auth?.user);

  useEffect(() => {
    M.Sidenav.init(document.querySelectorAll(".sidenav"), {});
    M.AutoInit();
    var elems = document.querySelectorAll(".dropdown-trigger");
    M.Dropdown.init(elems, {
      coverTrigger: false,
      constrainWidth: false,
      alignment: "right",
    });

    var elemsM = document.querySelectorAll(".modal");
    //eslint-disable-next-line
    var instances = M.Modal.init(elemsM, {});
  }, []);

  const logout = () => {
    dispatch(
      api_request(
        method_post,
        "/logout",
        {},
        {},
        LOGOUT_LOADING,
        LOGOUT_SUCCESS,
        LOGOUT_ERROR,
        user?.accessToken
      )
    );
  };
  const goHome = () => {
    navigate(home_path_2);
  };

  return (
    <>
      <nav className="nav" style={{ height: "120px" }}>
        <div className="nav-wrapper">
          <img
            className="brand-logo onHover"
            src="./images/peopleai-logo-transparent.png"
            alt="./images/peopleai-logo-transparent.png"
            height="105px"
            onClick={goHome}
          />
          <a
            href="/"
            data-target="mobile-demo"
            id="navbar-menu-icon"
            className="sidenav-trigger"
          >
            <i className="material-icons">menu</i>
          </a>
          {user && user?.role !== "demo" && (
            <ul
              className="right hide-on-med-and-down"
              style={{ paddingRight: 10 }}
            >
              <li>
                <Link
                  id="nav-drop-down"
                  className="dropdown-trigger"
                  to="#"
                  data-target="nav-mobile"
                >
                  <i className="material-icons right">arrow_drop_down</i>
                  {`Welcome, ${user?.userDetails?.userName}`}
                </Link>
              </li>
            </ul>
          )}
          {user?.role === "demo" && (
            <ul
              className="right hide-on-med-and-down"
              style={{ paddingRight: 10, marginTop: "1.3%" }}
            >
              <Link to={login_path}> Login / Register</Link>
            </ul>
          )}
          <ul
            id="nav-mobile"
            className={user ? "dropdown-content" : "right hide-on-med-and-down"}
            style={{ marginTop: "2%" }}
          >
            <li>
              <Link to={home_path_2}>Home</Link>
            </li>

            {user ? (
              <>
                {user?.role === role_admin && (
                  <>
                    <li>
                      <Link to={user_list_path}>Users</Link>
                    </li>
                    <li>
                      <Link to={last_sql_past}>Last Queries</Link>
                    </li>
                  </>
                )}
                {user?.subscribed && (
                  <>
                    <li>
                      <Link to={salary_dashboard_path}>Salary Dashboard</Link>
                    </li>
                    {/* <li>
                      <Link to={blog_path}>Comments</Link>
                    </li> */}
                  </>
                )}
                <li>
                  <Link to={salary_dashboard_path} onClick={logout}>
                    Logout
                  </Link>
                </li>
              </>
            ) : (
              <>
                <li>
                  <Link to={login_path}>Login/Register</Link>
                </li>
              </>
            )}
            <li>
              <Link to={contact_us_path}>Contact Us</Link>
            </li>
          </ul>
          <ul className="sidenav" id="mobile-demo">
            <li>
              <Link to={home_path_2}>Home</Link>
            </li>

            {!user ? (
              <>
                <li>
                  <Link to={login_path}>Login/Register</Link>
                </li>
              </>
            ) : (
              <>
                {user?.role === role_admin && (
                  <>
                    <li>
                      <Link to={user_list_path}>Users</Link>
                    </li>
                    <li>
                      <Link to={last_sql_past}>Last Queries</Link>
                    </li>
                  </>
                )}
                {user?.subscribed && (
                  <>
                    <li>
                      <Link to={salary_dashboard_path}>Salary Dashboard</Link>
                    </li>
                    {/* <li>
                      <Link to={blog_path}>Comments</Link>
                    </li> */}
                  </>
                )}
                <li>
                  <Link to={salary_dashboard_path} onClick={logout}>
                    Logout
                  </Link>
                </li>
              </>
            )}
            <li>
              <Link to={contact_us_path}>Contact Us</Link>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}
