import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { setSalarydashboard } from "../../../store/action/salaryDashboardAction";
import { customYAxisFormatter } from "../../../utils/salaryDashboardDataHeler";
import LoaderComp from "../../Layouts/LoaderComp";

// Define pastel colors
const pastelColors = [
  "#ff9999",
  "#66b3ff",
  "#99ff99",
  "#ffcc99",
  "#c2c2f0",
  "#ffb3e6",
  "#ff6666",
  "#c2f0c2",
];

export default function MedianByMonthSkills({ role }) {
  const dispatch = useDispatch();
  const isDemoUser = role === "demo";

  const chart5 = useSelector((state) => state.salaryDashboard?.chart5);
  const chart5loading = useSelector(
    (state) => state.salaryDashboard?.chart5loading
  );
  const chart5success = useSelector(
    (state) => state.salaryDashboard?.chart5success
  );
  const chart5error = useSelector(
    (state) => state.salaryDashboard?.chart5error
  );
  const common = useSelector((state) => state.common);
  const skills = common.salaryDashboard?.skill?.split(",");

  const [data, setData] = useState(null);
  const [months, setMonths] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Check if the screen width is less than a certain value (e.g., 768px) to determine if it's a mobile device
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    // Add an event listener to handle window resizing
    window.addEventListener("resize", handleResize);

    // Initial check
    handleResize();

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (chart5) {
      let m = ["6 months", "3 months", "30 days"];
      let lastMonths = [];
      let d = [];
      for (let i = 0; i < m.length; i++) {
        lastMonths.push(`Last ${m[i]} `);
        for (
          let s = 0;
          skills?.length > 0 && s < skills.length && skills[s] !== "";
          s++
        ) {
          const skill_data = chart5.filter((f) => f.skill === skills[s].trim());
          if (skill_data?.length !== 0) {
            const skill_index = d.findIndex((f) => f.name === skills[s].trim());
            const index = skill_data.findIndex((f) => f.days === m[i]);
            let item = null;
            if (index === -1) {
              item = 0;
            } else {
              item = skill_data[index].median;
            }
            if (skill_index === -1) {
              d.push({
                name: skills[s].trim(),
                data: [item],
              });
            } else {
              d[skill_index].data.push(item);
            }
          }
        }
      }
      setData(d);
      setMonths(lastMonths);
      dispatch(setSalarydashboard({ chart5success: false }));
    }
    //eslint-disable-next-line
  }, [chart5success, chart5]);

  useEffect(() => {
    if (chart5loading) {
      setData(null);
      setMonths(null);
    }
  }, [chart5loading]);

  const options = {
    legend: {
      show: !isDemoUser, // Hide legend if demo user
      labels: {
        colors: ["#000"],
      },
    },
    chart: {
      id: "line-chart",
      toolbar: {
        show: false,
      },
      height: 400,
    },
    stroke: {
      width: 3,
      curve: "smooth",
    },
    colors: data?.map((_, index) => pastelColors[index % pastelColors.length]), // Assign pastel colors to lines
    yaxis: {
      title: {
        text: isMobile ? "" : "Salary ", // Y-axis title
        style: {
          color: isDemoUser ? "#cccccc" : "#000", // Gray out Y-axis title if demo user
        },
      },
      labels: {
        show: !isDemoUser,
        formatter: customYAxisFormatter,
        style: {
          colors: isDemoUser ? "#cccccc" : "#000", // Gray out y-axis labels if demo user
        },
      },
      tickAmount: 5,
      min: 5000,
      max: 300000,
    },
    xaxis: {
      categories: months,
      labels: {
        show: !isDemoUser,
        style: {
          colors: isDemoUser ? "#cccccc" : "#000000", // Gray out x-axis labels if demo user
        },
      },
    },
  };

  return (
    <div className={`graph z-depth-3 ${isDemoUser ? "disabled-chart" : ""}`}>
      {data?.length > 0 &&
      months?.length > 0 &&
      !chart5loading &&
      !chart5error &&
      common.salaryDashboard?.skill &&
      common.salaryDashboard?.skill.trim() !== "" ? (
        <div className="graph-div">
          <span className="graph-heading">
            {`Change of median salary for "${common?.salaryDashboard?.searchRole}" jobs by month in ${common.salaryDashboard?.location} for selected skill`}
          </span>
          <ReactApexChart
            options={options}
            series={data}
            type="line"
            height={300}
          />
        </div>
      ) : (
        <div className="graph-nodata-div">
          <h6 className="graph-heading">
            {`Change of Median Salary by Month in ${common.salaryDashboard?.location} for selected skill`}
          </h6>
          {chart5loading ? (
            <LoaderComp />
          ) : common.salaryDashboard?.skill &&
            common.salaryDashboard?.skill.trim() !== "" ? (
            <span>No Data</span>
          ) : (
            <span>No skills have been given</span>
          )}
        </div>
      )}
    </div>
  );
}
