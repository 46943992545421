import React from "react";
import Navbar from "../Layouts/Navbar";
import { useSelector } from "react-redux";
import SqlOutput from "./SqlOutput";
import { Navigate } from "react-router-dom";
import { login_path } from "../../config/config";
import Footer from "../Layouts/Footer";

export default function LastSql() {
  const user = useSelector((state) => state.auth?.user);

  const sql1 = useSelector((state) => state.salaryDashboard?.sql1);
  const chart1 = useSelector((state) => state.salaryDashboard?.chart1);

  const sql2 = useSelector((state) => state.salaryDashboard?.sql2);

  const chart2 = useSelector((state) => state.salaryDashboard?.chart2);

  const sql3 = useSelector((state) => state.salaryDashboard?.sql3);
  const chart3 = useSelector((state) => state.salaryDashboard?.chart3);

  const sql4 = useSelector((state) => state.salaryDashboard?.sql4);
  const chart4 = useSelector((state) => state.salaryDashboard?.chart4);

  const sql5 = useSelector((state) => state.salaryDashboard?.sql5);
  const chart5 = useSelector((state) => state.salaryDashboard?.chart5);

  const sql6 = useSelector((state) => state.salaryDashboard?.sql6);
  const chart6 = useSelector((state) => state.salaryDashboard?.chart6);

  const sql7 = useSelector((state) => state.salaryDashboard?.sql7);
  const chart7 = useSelector((state) => state.salaryDashboard?.chart7);

  const sql8 = useSelector((state) => state.salaryDashboard?.sql8);

  if (!user) {
    return <Navigate to={login_path} />;
  }

  const chart4CodeUsed = `
  const calculatePercentile = (sortedData, percentile) => {
    const index = (percentile / 100) * sortedData.length;
    if (index % 1 === 0) {
      return (
        (sortedData[index - 1]?.average_salary +
          sortedData[index]?.average_salary) /
        2
      );
    } else {
      return sortedData[Math.floor(index)]?.average_salary;
    }
  }

  const sortedData = [...chart4].sort(
    (a, b) => a?.average_salary - b?.average_salary
  );

  // Calculate percentiles
  const median = calculatePercentile(sortedData, 50);
  const percentile25 = calculatePercentile(sortedData, 25);
  const percentile75 = calculatePercentile(sortedData, 75);
  
  ;`;

  return (
    <>
      <Navbar />
      <div id="sql-report">
        <div id="sql-report-card">
          {sql1 && <SqlOutput label="Chart 1" sql={sql1} output={chart1} />}
          {sql2 && <SqlOutput label="Chart 2" sql={sql2} output={chart2} />}
          {sql3 && <SqlOutput label="Chart 3" sql={sql3} output={chart3} />}
          {sql4 && (
            <SqlOutput
              label="Chart 4"
              sql={sql4}
              output={chart4.slice(0, 100)}
              optional={chart4CodeUsed}
            />
          )}
          {sql5 && <SqlOutput label="Chart 5" sql={sql5} output={chart5} />}
          {sql6 && <SqlOutput label="Chart 6" sql={sql6} output={chart6} />}
          {sql7 && <SqlOutput label="Chart 7" sql={sql7} output={chart7} />}
          {sql8 && <SqlOutput label="Chart 8" sql={sql8} output={""} />}
        </div>
      </div>
      <Footer />
    </>
  );
}
