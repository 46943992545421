import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { setSalarydashboard } from "../../../store/action/salaryDashboardAction";
import { customYAxisFormatter } from "../../../utils/salaryDashboardDataHeler";
import LoaderComp from "../../Layouts/LoaderComp";
import { graph_blue_bar } from "../../../config/config";

export default function MedianByMonth({ role }) {
  const dispatch = useDispatch();
  const isDemoUser = role === "demo";

  const chart6 = useSelector((state) => state.salaryDashboard?.chart6);
  const chart6loading = useSelector(
    (state) => state.salaryDashboard?.chart6loading
  );
  const chart6success = useSelector(
    (state) => state.salaryDashboard?.chart6success
  );
  const common = useSelector((state) => state.common);

  const [data, setData] = useState(null);
  const [months, setMonths] = useState(null);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(0);

  useEffect(() => {
    if (chart6) {
      let d = [
        {
          name: "Median",
          data: [],
        },
      ];
      let m = ["6 months", "3 months", "30 days"];
      let lastMonths = [];
      for (let i = 0; i < m.length; i++) {
        const index = chart6.findIndex((f) => f.days === m[i]);
        lastMonths.push(`Last ${m[i]}`);
        if (index === -1) {
          d[0].data.push(0);
        } else {
          let median = chart6[index].median;
          d[0].data.push(median);
        }
      }

      setMinValue(Math.min(...d[0].data));
      setMaxValue(Math.max(...d[0].data));
      setData(d);
      setMonths(lastMonths);
      dispatch(setSalarydashboard({ chart6success: false }));
    }
    //eslint-disable-next-line
  }, [chart6success, chart6]);

  useEffect(() => {
    if (chart6loading) {
      setData(null);
      setMonths(null);
    }
  }, [chart6loading]);

  const options = {
    legend: {
      show: !isDemoUser, // Hide legend if demo user
      labels: {
        colors: ["#f7f7f7"],
      },
    },
    stroke: {
      width: 3,
      curve: "smooth",
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: graph_blue_bar,
        shadeIntensity: 0.1,
        opacityFrom: 0.8,
        opacityTo: 0.9,
        stops: [0, 95, 100],
      },
    },
    chart: {
      id: "line-chart",
      type: "area",
      toolbar: {
        show: false,
      },
      height: 400,
    },
    yaxis: {
      labels: {
        show: !isDemoUser,
        formatter: customYAxisFormatter,
        style: {
          colors: isDemoUser ? "#cccccc" : "#000", // Gray out y-axis labels if demo user
        },
      },
      title: {
        text: isDemoUser ? "" : "Salary", // Y-axis title
        style: {
          color: isDemoUser ? "#cccccc" : "#000", // Gray out Y-axis title if demo user
        },
      },
      min: minValue > 0 ? 5000 : 0,
      max: 300000,
      tickAmount: 5,
    },
    xaxis: {
      categories: months,
      labels: {
        show: !isDemoUser,
        style: {
          colors: isDemoUser ? "#cccccc" : "#000000", // Gray out x-axis labels if demo user
        },
      },
    },
    tooltip: {
      theme: isDemoUser ? "dark" : "light", // Set tooltip theme if demo user
    },
  };

  return (
    <div className={`graph z-depth-3 ${isDemoUser ? "disabled-chart" : ""}`}>
      {data?.length > 0 && months?.length > 0 ? (
        <div className="graph-div">
          <span className="graph-heading">
            {`Change of median salary for "${common?.salaryDashboard?.searchRole}" jobs by month in ${common.salaryDashboard?.location}`}
          </span>
          <ReactApexChart
            options={options}
            series={data}
            type="line"
            height={300}
          />
        </div>
      ) : (
        <div className="graph-nodata-div">
          <h6 className="graph-heading">{`Change of Median Salary by Month in ${common.salaryDashboard?.location}`}</h6>
          {chart6loading ? <LoaderComp /> : <span>No Data</span>}
        </div>
      )}
    </div>
  );
}
