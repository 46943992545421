import {
  BLOG_POST_ERROR,
  BLOG_POST_LOADING,
  BLOG_POST_SUCCESS,
  GET_COMMENTS_LOAADING,
  GET_COMMENTS_SUCCCESS,
  GET_COMMENT_ERROR,
  SET_BLOG_REDUCER,
} from "../types/blogTypes";

const initState = {
  blogpostLoading: false,
  blogpostSuccess: false,
  blogpostErr: false,
  blogpostMsg: null,

  getCommmentsLoading: false,
  getCommmentsSuccess: false,
  getCommmentsError: false,
  comments: null,
};

const blogReducer = (state = initState, action) => {
  //   console.log(action);
  switch (action.type) {
    case BLOG_POST_LOADING:
      return {
        ...state,
        blogpostLoading: true,
        blogpostSuccess: false,
        blogpostErr: false,
        blogpostMsg: null,
      };
    case BLOG_POST_SUCCESS:
      return {
        ...state,
        blogpostLoading: false,
        blogpostSuccess: true,
        blogpostErr: false,
        blogpostMsg: action.message || "Blog posted successfully",
      };
    case BLOG_POST_ERROR:
      return {
        ...state,
        blogpostLoading: false,
        blogpostSuccess: false,
        blogpostErr: true,
        blogpostMsg: action.message || "Unexpected error",
      };
    case GET_COMMENTS_LOAADING:
      return {
        ...state,
        getCommmentsLoading: true,
        getCommmentsSuccess: false,
        getCommmentsError: false,
        comments: null,
      };
    case GET_COMMENTS_SUCCCESS:
      return {
        ...state,
        getCommmentsLoading: false,
        getCommmentsSuccess: true,
        getCommmentsError: false,
        comments: action.payload,
      };
    case GET_COMMENT_ERROR:
      return {
        ...state,
        getCommmentsLoading: false,
        getCommmentsSuccess: false,
        getCommmentsError: true,
        comments: null,
      };
    case SET_BLOG_REDUCER:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default blogReducer;
