import React from "react";

export default function CustomInput2(props) {
  const { formikProps, id, ...restProps } = props;
  return (
    <>
      <input
        id={id}
        {...restProps}
        style={{
          backgroundColor: "#ffffff",
          borderRadius: "0",
          border: "NONE",
          paddingLeft: "3%",
        }}
      />
      {formikProps?.touched[id] && formikProps?.errors[id] ? (
        <span className="error-text">
          {formikProps?.touched[id] && formikProps?.errors[id]}
        </span>
      ) : null}
    </>
  );
}
