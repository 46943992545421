import {
  CHART1_ERROR,
  CHART1_LOADING,
  CHART1_SUCCESS,
  CHART2_ERROR,
  CHART2_LOADING,
  CHART2_SUCCESS,
  CHART3_ERROR,
  CHART3_LOADING,
  CHART3_SUCCESS,
  CHART4_ERROR,
  CHART4_LOADING,
  CHART4_SUCCESS,
  CHART5_ERROR,
  CHART5_LOADING,
  CHART5_SUCCESS,
  CHART6_ERROR,
  CHART6_LOADING,
  CHART6_SUCCESS,
  CHART7_ERROR,
  CHART7_LOADING,
  CHART7_SUCCESS,
  CHART8_ERROR,
  CHART8_LOADING,
  CHART8_SUCCESS,
  FETCH_COMPANY_ERROR,
  FETCH_COMPANY_LOADING,
  FETCH_COMPANY_SUCCESS,
  JOB_CATEGORY_FETCH_ERROR,
  JOB_CATEGORY_FETCH_LOADING,
  JOB_CATEGORY_FETCH_SUCCESS,
  SET_SALARY_DASHBOARD,
} from "../types/salaryDashBoardReducerTypes";

const initState = {
  jobCategoryGetLoading: false,
  jobCategoryGetSuccess: false,
  jobCategoryGetError: false,
  jobCategory: null,

  graphDataGetLoading: false,
  graphDataGetSuccess: false,
  graphDataGetError: false,
  graphData: null,

  sql1: null,
  chart1: null,
  chart1loading: false,
  chart1success: false,
  chart1error: false,

  sql2: null,
  chart2: null,
  chart2loading: false,
  chart2success: false,
  chart2error: false,

  sql3: null,
  chart3: null,
  chart3loading: false,
  chart3success: false,
  chart3error: false,

  sql4: null,
  chart4: null,
  chart4loading: false,
  chart4success: false,
  chart4error: false,

  sql5: null,
  chart5: null,
  chart5loading: false,
  chart5success: false,
  chart5error: false,

  sql6: null,
  chart6: null,
  chart6loading: false,
  chart6success: false,
  chart6error: false,

  sql7: null,
  chart7: null,
  chart7loading: false,
  chart7success: false,
  chart7error: false,

  fetchCompanyLoading: false,
  fetchCompanySuccess: false,
  fetchCompanyError: false,
  company: null,
};

const salaryDashBoardReducer = (state = initState, action) => {
  // console.log(action);
  switch (action.type) {
    case JOB_CATEGORY_FETCH_LOADING:
      return {
        ...state,
        jobCategoryGetLoading: true,
        jobCategoryGetSuccess: false,
        jobCategoryGetError: false,
        jobCategory: null,
      };
    case JOB_CATEGORY_FETCH_SUCCESS:
      return {
        ...state,
        jobCategoryGetLoading: false,
        jobCategoryGetSuccess: true,
        jobCategoryGetError: false,
        jobCategory: action.payload,
      };
    case JOB_CATEGORY_FETCH_ERROR:
      return {
        ...state,
        jobCategoryGetLoading: false,
        jobCategoryGetSuccess: false,
        jobCategoryGetError: true,
        jobCategory: null,
      };
    case CHART1_LOADING:
      return {
        ...state,
        sql1: null,
        chart1: null,
        chart1loading: true,
        chart1success: false,
        chart1error: false,
      };
    case CHART1_SUCCESS:
      return {
        ...state,
        sql1: action.sql,
        chart1: action.payload,
        chart1loading: false,
        chart1success: true,
        chart1error: false,
      };
    case CHART1_ERROR:
      return {
        ...state,
        sql1: null,
        chart1: null,
        chart1loading: false,
        chart1success: false,
        chart1error: true,
      };
    case CHART2_LOADING:
      return {
        ...state,
        sql2: null,
        chart2: null,
        chart2loading: true,
        chart2success: false,
        chart2error: false,
      };
    case CHART2_SUCCESS:
      return {
        ...state,
        sql2: action.sql,
        chart2: action.payload,
        chart2loading: false,
        chart2success: true,
        chart2error: false,
      };
    case CHART2_ERROR:
      return {
        ...state,
        sql2: null,
        chart2: null,
        chart2loading: false,
        chart2success: false,
        chart2error: true,
      };
    case CHART3_LOADING:
      return {
        ...state,
        sql3: null,
        chart3: null,
        chart3loading: true,
        chart3success: false,
        chart3error: false,
      };
    case CHART3_SUCCESS:
      return {
        ...state,
        sql3: action.sql,
        chart3: action.payload,
        chart3loading: false,
        chart3success: true,
        chart3error: false,
      };
    case CHART3_ERROR:
      return {
        ...state,
        sql3: null,
        chart3: null,
        chart3loading: false,
        chart3success: false,
        chart3error: true,
      };
    case CHART4_LOADING:
      return {
        ...state,
        sql4: null,
        chart4: null,
        chart4loading: true,
        chart4success: false,
        chart4error: false,
      };
    case CHART4_SUCCESS:
      return {
        ...state,
        sql4: action.sql,
        chart4: action.payload,
        chart4loading: false,
        chart4success: true,
        chart4error: false,
      };
    case CHART4_ERROR:
      return {
        ...state,
        sql4: null,
        chart4: null,
        chart4loading: false,
        chart4success: false,
        chart4error: true,
      };
    case CHART5_LOADING:
      return {
        ...state,
        sql5: null,
        chart5: null,
        chart5loading: true,
        chart5success: false,
        chart5error: false,
      };
    case CHART5_SUCCESS:
      return {
        ...state,
        sql5: action.sql,
        chart5: action.payload,
        chart5loading: false,
        chart5success: true,
        chart5error: false,
      };
    case CHART5_ERROR:
      return {
        ...state,
        sql5: null,
        chart5: null,
        chart5loading: false,
        chart5success: false,
        chart5error: true,
      };
    case CHART6_LOADING:
      return {
        ...state,
        sql6: null,
        chart6: null,
        chart6loading: true,
        chart6success: false,
        chart6error: false,
      };
    case CHART6_SUCCESS:
      return {
        ...state,
        sql6: action.sql,
        chart6: action.payload,
        chart6loading: false,
        chart6success: true,
        chart6error: false,
      };
    case CHART6_ERROR:
      return {
        ...state,
        sql6: null,
        chart6: null,
        chart6loading: false,
        chart6success: false,
        chart6error: true,
      };
      case CHART7_LOADING:
        return {
          ...state,
          sql7: null,
          chart7: null,
          chart7loading: true,
          chart7success: false,
          chart7error: false,
        };
      case CHART7_SUCCESS:
        return {
          ...state,
          sql7: action.sql,
          chart7: action.payload,
          chart7loading: false,
          chart7success: true,
          chart7error: false,
        };
      case CHART7_ERROR:
        return {
          ...state,
          sql7: null,
          chart7: null,
          chart7loading: false,
          chart7success: false,
          chart7error: true,
        };
        case CHART8_LOADING:
          return {
            ...state,
            sql8: null,
            chart8: null,
            chart8loading: true,
            chart8success: false,
            chart8error: false,
          };
        case CHART8_SUCCESS:
          return {
            ...state,
            sql8: action.sql,
            chart8: action.payload,
            chart8loading: false,
            chart8success: true,
            chart8error: false,
          };
        case CHART8_ERROR:
          return {
            ...state,
            sql8: null,
            chart8: null,
            chart8loading: false,
            chart8success: false,
            chart8error: true,
          };
    case FETCH_COMPANY_LOADING:
      return {
        ...state,
        fetchCompanyLoading: true,
        fetchCompanySuccess: false,
        fetchCompanyError: false,
        company: null,
      };
    case FETCH_COMPANY_SUCCESS:
      return {
        ...state,
        fetchCompanyLoading: false,
        fetchCompanySuccess: true,
        fetchCompanyError: false,
        company: action.payload,
      };
    case FETCH_COMPANY_ERROR:
      return {
        ...state,
        fetchCompanyLoading: false,
        fetchCompanySuccess: false,
        fetchCompanyError: true,
        company: null,
      };
    case SET_SALARY_DASHBOARD:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default salaryDashBoardReducer;
