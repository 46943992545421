import React from "react";
import JobPercentPie from "./JobPercentPie";
import MedianSalaryColumnChart from "./MedianSalaryColumnChart";
import MedianSalaryGauge from "./MedianSalaryGauge";
import MedianSalarySkill from "./MedianSalarySkill";
import MedianByMonth from "./MedianByMonth";
import MedianByMonthSkills from "./MedianByMonthSkills";
import ContractVsPermanentChart from "./ContractVsPermanent";
import NoOfJobsByCategories from "./NoOfJobsByCategories";
import { useSelector } from "react-redux";
import { login_path, registration_path } from "../../../config/config";
import { Link } from "react-router-dom";

export default function Graphs() {
  const user = useSelector((state) => state.auth?.user);
  const role = user?.role;
  const isDemoUser = user?.role === "demo";

  return (
    <div>
      {isDemoUser && (
        <p style={{ marginTop: "20px", textAlign: "center" }}>
          To access all the charts, please{" "}
          <Link to={login_path}>log in to your account</Link> or{" "}
          <Link to={registration_path}>register for a new one</Link>. Certain
          features, including chart data, are available only to registered
          users.
        </p>
      )}

      <div className="row graphs-container">
        <div className="col s12 l6 gap2 custom custom-padding">
          <JobPercentPie />
        </div>
        <div
          className={`col s12 l6 gap2 custom-padding ${
            isDemoUser ? "disabled-chart" : ""
          }`}
          style={{ pageBreakAfter: "always" }}
        >
          <MedianSalaryColumnChart role={role} />
        </div>
        <div
          className="col s12 l6 gap2 custom-padding"
          style={{ pageBreakBefore: "auto", pageBreakInside: "avoid" }}
        >
          <MedianSalarySkill role={role} />
        </div>
        <div
          className="col s12 l6 gap2 custom-padding"
          style={{ pageBreakBefore: "auto", pageBreakInside: "avoid" }}
        >
          <MedianSalaryGauge role={role} />
        </div>
        <div
          className="col s12 l6 gap2 custom-padding"
          style={{ pageBreakBefore: "auto", pageBreakInside: "avoid" }}
        >
          <MedianByMonthSkills role={role} />
        </div>
        <div
          className="col s12 l6 gap2 custom-padding"
          style={{ pageBreakBefore: "auto", pageBreakInside: "avoid" }}
        >
          <MedianByMonth role={role} />
        </div>
        <div
          className="col s12 l6 gap2 custom-padding"
          style={{ pageBreakBefore: "auto", pageBreakInside: "avoid" }}
        >
          <ContractVsPermanentChart role={role} />
        </div>
        <div
          className="col s12 l6 gap2 custom-padding"
          style={{ pageBreakBefore: "auto", pageBreakInside: "avoid" }}
        >
          <NoOfJobsByCategories role={role} />
        </div>
      </div>
    </div>
  );
}
