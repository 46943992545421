import React, { Component } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./Components/Auth/Login";
import "./styles/common.css";
import SalaryDashboard from "./Components/Dashboard/SalaryDashboard/SalaryDashboard";
import Home from "./Components/Home/Home";
import {
  blog_path,
  change_password_path,
  contact_us_path,
  demo_path,
  last_sql_past,
  login_path,
  method_post,
  privacy_policy_path,
  refreshInterval,
  registration_path,
  reset_password_path,
  salary_dashboard_path,
  subscription_path,
  terms_condition_path,
  test_path,
  user_activity_log_path,
  user_list_path,
  verify_user,
} from "./config/config";
import Register from "./Components/Auth/Register";
import ResetPassword from "./Components/Auth/ResetPassword";
import VerifyUser from "./Components/Auth/VerifyUser";
import { connect } from "react-redux";
import { api_request } from "./store/action/commonActions";
import {
  TOKEN_REFRESH_ERROR,
  TOKEN_REFRESH_SUCCESS,
} from "./store/types/authTypes";
import ChangePassword from "./Components/Auth/ChangePassword";
import TermsandCondt from "./Components/Home/TermsandCondt";
import PrivacyPolicy from "./Components/Home/PrivacyPolicy";
import UserList from "./Components/UserActivity.js/UserList";
import UserActivityLog from "./Components/UserActivity.js/UserActivityLog";
import Test from "./Components/Test/Test";
import Subscribe from "./Components/Subscribe/Subscribe";
import LastSql from "./Components/AdminDebug/LastSql";
import ContactUs from "./Components/Home/ContactUs";
import Demo from "./Components/Home/Demo";
import Comments from "./Components/Comments/Comments";

class App extends Component {
  componentDidUpdate() {
    const { auth, RefreshToken } = this.props;
    if (auth && auth.user && auth.user.refreshAccessToken && RefreshToken) {
      if (this.refreshTimer) clearInterval(this.refreshTimer);
      this.refreshTimer = setInterval(() => {
        RefreshToken(
          method_post,
          "/refreshtoken",
          {
            refreshAccessToken: auth.user.refreshAccessToken,
          },
          null,
          null,
          TOKEN_REFRESH_SUCCESS,
          TOKEN_REFRESH_ERROR,
          auth.user.accessToken
        );
      }, refreshInterval);
    }
  }

  componentWillUnmount() {
    if (this.refreshTimer) clearInterval(this.refreshTimer);
  }

  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route index element={<SalaryDashboard />} />
          <Route path={salary_dashboard_path} element={<SalaryDashboard />} />
          <Route path={login_path} element={<Login />} />
          <Route path={registration_path} element={<Register />} />
          <Route path={reset_password_path} element={<ResetPassword />} />
          <Route path={verify_user} element={<VerifyUser />} />
          <Route path={change_password_path} element={<ChangePassword />} />
          <Route path={terms_condition_path} element={<TermsandCondt />} />
          <Route path={privacy_policy_path} element={<PrivacyPolicy />} />
          <Route path={user_list_path} element={<UserList />} />
          <Route path={user_activity_log_path} element={<UserActivityLog />} />
          <Route path={test_path} element={<Test />} />
          <Route path={subscription_path} element={<Subscribe />} />
          <Route path={last_sql_past} element={<LastSql />} />
          <Route path={contact_us_path} element={<ContactUs />} />
          <Route path={demo_path} element={<Demo />} />
          <Route path={blog_path} element={<Comments />} />
        </Routes>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    auth: state.auth,
    authLoading: state.auth.authLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    RefreshToken: (
      method,
      path,
      body,
      query,
      loadType,
      successType,
      errorType,
      accessToken
    ) => {
      dispatch(
        api_request(
          method,
          path,
          body,
          query,
          loadType,
          successType,
          errorType,
          accessToken
        )
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
