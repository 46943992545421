import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";
import LoaderComp from "../../Layouts/LoaderComp";
import { pastelColors } from "../../../config/constants";

export default function ContractVsPermanentChart({ role }) {
  const isDemoUser = role === "demo";

  const chart7 = useSelector((state) => state.salaryDashboard?.chart7);
  const chart7loading = useSelector(
    (state) => state.salaryDashboard?.chart7loading
  );
  const chart7success = useSelector(
    (state) => state.salaryDashboard?.chart7success
  );
  //eslint-disable-next-line
  const chart7error = useSelector(
    (state) => state.salaryDashboard?.chart7error
  );
  const common = useSelector((state) => state.common);

  const options = {
    title: {
      align: "center",
      style: {
        color: isDemoUser ? "#cccccc" : "#333", // Gray out title if demo user
      },
    },
    labels: isDemoUser ? [] : ["Contract", "Permanent"], // Hide labels if demo user
    colors: [pastelColors[0], pastelColors[1]],
    legend: {
      show: !isDemoUser, // Hide legend if demo user
      position: "bottom",
      labels: {
        colors: isDemoUser ? "#cccccc" : "#333", // Gray out legend labels if demo user
      },
    },
    tooltip: {
      enabled: !isDemoUser, // Disable tooltip if demo user
    },
  };

  const [series, setSeries] = useState([0, 0]);

  useEffect(() => {
    if (chart7) {
      setSeries([
        parseInt(chart7[0].contractCount),
        parseInt(chart7[0].permanentCount),
      ]);
    }
  }, [chart7, chart7success]);

  useEffect(() => {
    if (chart7loading) {
      setSeries([0, 0]);
    }
  }, [chart7loading]);

  return (
    <div className={`graph z-depth-3 ${isDemoUser ? "disabled-chart" : ""}`}>
      {series.reduce((a, b) => a + b, 0) > 0 ? (
        <div>
          <span
            style={{
              color: isDemoUser ? "#cccccc" : "black",
              marginBottom: "20px",
            }}
          >
            {`Distribution of contract vs. permanent jobs in ${common.salaryDashboard?.location}`}
          </span>
          <Chart options={options} series={series} type="donut" />
        </div>
      ) : (
        <div className="graph-nodata-div">
          <h6
            className="graph-heading"
            style={{ color: isDemoUser ? "#cccccc" : "black" }}
          >
            {`Distribution of contract vs. permanent jobs in ${common.salaryDashboard?.location}`}
          </h6>

          {chart7loading ? <LoaderComp /> : <span>No Data</span>}
        </div>
      )}
    </div>
  );
}
