export const locations = [
  "Adelaide SA",
  "Brisbane QLD",
  "Canberra ACT",
  "Darwin NT",
  "Gold Coast QLD",
  "Hobart TAS",
  "Melbourne VIC",
  "Newcastle NSW",
  "Perth WA",
  "Sydney NSW",
  // "Wollongong NSW",
  "Others",
];

export const contactTypes = ["Salary", "Payment", "Both"];

export const experience = [
  { label: "0-3 Years", value: 0.9 },
  { label: "4-6 years", value: 1 },
  { label: "7+ years", value: 1.1 },
];

export const classification = [
  "Accounting",
  "Administration & Office Support",
  "Advertising, Arts & Media",
  "Banking & Financial Services",
  "Call Centre & Customer Service",
  "CEO & General Management",
  "Community Services & Development",
  "Construction",
  "Consulting & Strategy",
  "Design & Architecture",
  "Education & Training",
  "Engineering",
  "Farming, Animals & Conservation",
  "Government & Defence",
  "Healthcare & Medical",
  "Hospitality & Tourism",
  "HR",
  "ICT",
  "Insurance & Superannuation", //pend
  "Legal",
  "Manufacturing, Transport & Logistics",
  "Marketing & Communications", //pend
  "Mining, Resources & Energy",
  "Real Estate & Property",
  "Retail & Consumer Products",
  "Sales",
  "Science & Technology",
  "Self Employment",
  "Sport & Recreation",
  // "Trades & Services",
];

export const MONTHS = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
];

export const locations_color = {
  "adelaide sa": "#807cfc",
  "brisbane qld": "#d8d7fc",
  "canberra, act": "#5f5ccc",
  "darwin nt": "#afacfa",
  "gold coast qld": "#c1bfff",
  "hobart tas": "#504cba",
  "melbourne vic": "#e4e3ff",
  "newcastle nsw": "#726efa",
  "perth wa": "#9d9afc",
  "sydney nsw": "#ceccff",
  "wollongong nsw": "#928fff",
  other: "#403d9c",
};

export const salaryHomeStatements = [
  "This is completely real-time – no more waiting for 6 months to 12 months to get most recent data; our data gets uploaded each week.",
  "Salary surveys don’t tell you salaries by skills required for the job – our tool allows you to see which skills are more in demand and how much you should be paying for that salary.",
  "Salary surveys very rarely give you information about city/location premiums – our proprietary tool will give you salary data by city and locations.",
  "You don’t need to spend weeks on submitting data in salary surveys – just access our tool anytime.",
  "The best part, accessing our tool is about a third the price of accessing any salary surveys – from easy monthly payments to discounted annual payments, we have it all.",
];

export const payHomeStatements = [
  "A continuous real-time audit of the pay status across your organization that saves time and cost as compared to point-in-time manual audits.",
  "Our patented AI-based metric helps you understand your current pay equity with recommendations on your future action.",
];

export const pastelColors = [
  "#66DDAA", // Vivid Mint Green
  "#5A8DEE", // Vivid Blue
  "#ff6384", // Vivid Red
  "#FFB480", // Bright Orange
  "#FFD93D", // Vivid Yellow
  "#9AE66E", // Bright Green
  "#39C0ED", // Bright Cyan

  "#A97FFF", // Bright Purple
  "#FF87A2", // Vivid Pink

  "#FFD36E", // Bright Peach
  "#C16EFF", // Vivid Lilac
  "#FF758F", // Bright Coral
];
