import React from "react";
import StripeCheckout from "react-stripe-checkout";

export default function Test() {
  return (
    <>
      <StripeCheckout
        stripeKey="pk_test_51NKeYlSJzZRTqvxdQc6TzjEMg6DdZxH3pOWspLWKwk2gy4WMtPExpyEttwFMUKIoYLy5yJ53DsxqTrqIgtI8Mc2n00lOAUdx7V"
        // token={}
        amount={1000} // Amount in cents
        currency="INR"
        name="My Store"
        description="Purchase"
        panelLabel="Pay Now"
      />
    </>
  );
}
