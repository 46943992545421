export const JOB_CATEGORY_FETCH_LOADING = "JOB_CATEGORY_FETCH_LOADING";
export const JOB_CATEGORY_FETCH_SUCCESS = "JOB_CATEGORY_FETCH_SUCCESS";
export const JOB_CATEGORY_FETCH_ERROR = "JOB_CATEGORY_FETCH_ERROR";

export const SET_SALARY_DASHBOARD = "SET_SALARY_DASHBOARD";

export const CHART1_LOADING = "CHART1_LOADING";
export const CHART1_SUCCESS = "CHART1_SUCCESS";
export const CHART1_ERROR = "CHART1_ERROR";

export const CHART2_LOADING = "CHART2_LOADING";
export const CHART2_SUCCESS = "CHART2_SUCCESS";
export const CHART2_ERROR = "CHART2_ERROR";

export const CHART3_LOADING = "CHART3_LOADING";
export const CHART3_SUCCESS = "CHART3_SUCCESS";
export const CHART3_ERROR = "CHART3_ERROR";

export const CHART4_LOADING = "CHART4_LOADING";
export const CHART4_SUCCESS = "CHART4_SUCCESS";
export const CHART4_ERROR = "CHART4_ERROR";

export const CHART5_LOADING = "CHART5_LOADING";
export const CHART5_SUCCESS = "CHART5_SUCCESS";
export const CHART5_ERROR = "CHART5_ERROR";

export const CHART6_LOADING = "CHART6_LOADING";
export const CHART6_SUCCESS = "CHART6_SUCCESS";
export const CHART6_ERROR = "CHART6_ERROR";

export const CHART7_LOADING = "CHART7_LOADING";
export const CHART7_SUCCESS = "CHART7_SUCCESS";
export const CHART7_ERROR = "CHART7_ERROR";

export const CHART8_LOADING = "CHART8_LOADING";
export const CHART8_SUCCESS = "CHART8_SUCCESS";
export const CHART8_ERROR = "CHART8_ERROR";

export const FETCH_COMPANY_LOADING = "FETCH_COMPANY_LOADING";
export const FETCH_COMPANY_SUCCESS = "FETCH_COMPANY_SUCCESS";
export const FETCH_COMPANY_ERROR = "FETCH_COMPANY_ERROR";
