import moment from "moment-timezone";
import { timezone } from "../config/config";

export const getDateString = (dateObj, timeFlag = false) => {
  if (timeFlag) {
    return moment(dateObj.getTime())
      .tz(timezone)
      .format("YYYY-MM-DD, h:mm:ss a");
  } else {
    return moment(dateObj.getTime()).tz(timezone).format("YYYY-MM-DD");
  }
};

export const convertMonthsToMilliseconds = (months) => {
  return months * 1000 * 60 * 60 * 24 * 30;
};
