import { SET_PAYMENT_REDUCER } from "../types/paymentTypes";

export const setPaymentReducer = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_PAYMENT_REDUCER,
      payload: data,
    });
  };
};
