import {
  SENT_SUBSCRIPTION_MAIL_ERROR,
  SENT_SUBSCRIPTION_MAIL_LOADING,
  SENT_SUBSCRIPTION_MAIL_SUCCESS,
  SET_SUBSCRIPTION_REDUUCER,
} from "../types/subscriptionType";

const initState = {
  sentSubscriptionMailLoading: false,
  sentSubscriptionMailSuccess: false,
  sentSubscriptionMailError: false,
  sentSubscriptionMailMsg: null,
};

const subscriptionReducer = (state = initState, action) => {
  switch (action.type) {
    case SENT_SUBSCRIPTION_MAIL_LOADING:
      return {
        ...state,
        sentSubscriptionMailLoading: true,
        sentSubscriptionMailSuccess: false,
        sentSubscriptionMailError: false,
        sentSubscriptionMailMsg: null,
      };
    case SENT_SUBSCRIPTION_MAIL_SUCCESS:
      return {
        ...state,
        sentSubscriptionMailLoading: false,
        sentSubscriptionMailSuccess: true,
        sentSubscriptionMailError: false,
        sentSubscriptionMailMsg: action.message || "Mail sent",
      };
    case SENT_SUBSCRIPTION_MAIL_ERROR:
      return {
        ...state,
        sentSubscriptionMailLoading: false,
        sentSubscriptionMailSuccess: false,
        sentSubscriptionMailError: true,
        sentSubscriptionMailMsg: action.message || "Error to sent mail",
      };
    case SET_SUBSCRIPTION_REDUUCER:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default subscriptionReducer;
